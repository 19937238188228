<template>
    <section class="session-search">
        <h1 class="session-search__title">Posiedzenia</h1>
        <section class="session-search__bar">
            <section class="session-search__bar__item">
                <section class="session-search__bar__item__label">
                    Szukana fraza
                </section>
                <SearchInputTextComponent v-model="requestSearch.searchText"
                                          :width="'305'"
                                          :placeholder="'Wyszukaj posiedzenie...'" />
            </section>
            <section class="session-search__bar__item">
                <section class="session-search__bar__item__label">
                    Szukane grupy
                </section>
                <section class="session-search__bar__item__input">
                    <Multiselect v-model="options.groups.selected"
                                 placeholder="Wybierz grupę"
                                 mode="multiple"
                                 :close-on-select="true"
                                 class="session-search__bar__input session-search__bar__input"
                                 :searchable="true"
                                 :create-option="false"
                                 :object="true"
                                 :options="options.groups.available"
                                 noResultsText="brak wyników"
                                 noOptionsText="brak opcji"
                                 @select="filterGroupChange"
                                 @deselect="filterGroupChange"
                                 @clear="filterGroupClear"
                                 style="min-width: 280px; padding: 12.5px 16px;">
                        <template v-slot:multiplelabel="{ values }">
                            <div class="multiselect-multiple-label" v-if="values.length == 1">
                                Zaznaczono&nbsp;<strong>{{ values.length }}</strong>&nbsp;groupę
                            </div>
                            <div class="multiselect-multiple-label" v-else-if="values.length >= 2 && values.length < 5">
                                Zaznaczono&nbsp;<strong>{{ values.length }}</strong>&nbsp;grupy
                            </div>
                            <div class="multiselect-multiple-label" v-else>
                                Zaznaczono&nbsp;<strong>{{ values.length }}</strong>&nbsp;grup
                            </div>
                        </template>
                    </Multiselect>
                </section>
            </section>
            <section class="session-search__bar__item">
                <section class="session-search__bar__item__label">
                    Od
                </section>
                <section class="session-search__bar__item__input">
                    <input type="date"
                           v-model="requestSearch.dateFrom"
                           placeholder="Data do"
                           class="session-search__bar__input session-search__bar__input-date"
                           @input="changeDateFrom" />
                    <label class="session-search__bar__button-search" @click="() => { requestSearch.dateFrom = null; requestSearch.page = 1}">
                        <img src="../../../../assets/icons/components/exit-btn.svg"
                             class="session-search__bar__search-icon" />
                    </label>
                </section>
            </section>

            <section class="session-search__bar__item">
                <section class="session-search__bar__item__label">
                    Do
                </section>
                <section class="session-search__bar__item__input">
                    <input type="date"
                           v-model="requestSearch.dateTo"
                           placeholder="Data od"
                           class="session-search__bar__input session-search__bar__input-date"
                           @input="changeDateTo" />
                    <label class="session-search__bar__button-search" @click="() => { requestSearch.dateTo = null; requestSearch.page = 1}">
                        <img src="../../../../assets/icons/components/exit-btn.svg"
                             class="session-search__bar__search-icon" />
                    </label>
                </section>
            </section>

        </section>
        <section class="session-search__information">
            <section class="session-search__information__current-filters">
                <section class="session-search__information__current-filters__label">
                    Aktualne filtry
                </section>
                <section class="session-search__information__current-filters__items">
                    <SearchFilterItem v-if="requestSearch.searchText !== null & requestSearch.searchText !== ''"
                                      :prepend="'Szukana fraza:'"
                                      :value="requestSearch.searchText"
                                      @remove="() => { requestSearch.searchText = null}" />

                    <SearchFilterItem v-for="(item, index) in options.groups.selected"
                                      :key="index"
                                      :prepend="'Grupa:'"
                                      :value="item.label"
                                      :identifier="item.value"
                                      @remove="removeGroupFilter" />

                    <SearchFilterItem v-if="requestSearch.dateFrom !== null && requestSearch.dateFrom !== ''"
                                      :prepend="'Data od:'"
                                      :value="requestSearch.dateFrom"
                                      @remove="() => { requestSearch.dateFrom = null}" />

                    <SearchFilterItem v-if="requestSearch.dateTo !== null && requestSearch.dateTo !== ''"
                                      :prepend="'Data do:'"
                                      :value="requestSearch.dateTo"
                                      @remove="() => { requestSearch.dateTo = null}" />
                </section>
            </section>
            <section class="session-search__information__count-items" v-if="sessionList.length > 0">
                Wyświetlono {{getCurrentItems}} z {{totalItems}}
            </section>
        </section>
        <section class="session-search__list" v-if="sessionList.length > 0">
            <Pagination :totalItems="totalItems"
                        :currentPage="requestSearch.page"
                        :itemsPerPage="requestSearch.itemsPerPage"
                        @goToPage="changePage" />
            <section class="session-search__list__item"
                     v-for="(item, index) in sessionList"
                     :key="index"
                     @contextmenu="openContextMenu">
                <SessionListItem :session="item" />
            </section>
            <Pagination :totalItems="totalItems"
                        :currentPage="requestSearch.page"
                        :itemsPerPage="requestSearch.itemsPerPage"
                        @goToPage="changePage" />
        </section>
        <section class="session-search__list" v-else>
            <section class="session-search__list__no-items">
                Brak posiedzeń spełniających podane kryteria
            </section>
        </section>
    </section>

</template>
<script charset="utf-8">
    //components
    import Multiselect from '@vueform/multiselect'
    import SessionListItem from './SessionListItem.vue';
    import SearchFilterItem from '@/components/BasicControls/SearchFilterItem.vue';
    import Pagination from "@/components/BasicControls/Pagination.vue";
    import SearchInputTextComponent from "@/components/BasicControls/SearchInputTextComponent.vue";
    //servics
    import AlertHelper from '@/helpers/alerts.helper.js';
    import SessionService from '@/services/session.service';
    import ContactService from '@/services/contact.service';
    import { encryptStorage } from '../../../../services/encrypt.service';
    import { debounce } from '../../../../helpers/utils';

    export default {
        name: "SessionSearch",
        components: {
            Multiselect,
            SessionListItem,
            SearchFilterItem,
            Pagination,
            SearchInputTextComponent
        },
        data() {
            return {
                requestSearch: {
                    group: [],
                    dateFrom: null,
                    dateTo: null,
                    searchText: null,
                    page: 1,
                    itemsPerPage: 10,
                },

                totalItems: -1,

                options: {
                    groups: {
                        available: {},
                        selected: []
                    },
                },

                sessionList: [],

                tempContextMenu: null,
                isLoading: false,

                allGroups: [],

                bipModule: encryptStorage.getItem('modules').bip,
                camModule: encryptStorage.getItem('modules').cam,
            }
        },
        watch: {
            requestSearch: {
                deep: true,
                handler: debounce(function (newValue) {
                    let queryParam = Object.fromEntries(Object.entries(newValue).filter(([v]) => newValue[v] !== null && newValue[v] !== '' ));
                    this.$router.replace({ name: "sessionSearchRoute", query: queryParam });
                }, 150)
            },
            $route() {
                if (this.$route.name === "sessionSearchRoute" && Object.keys(this.$route.query).length > 0) {
                    this.queryParamsToRequestSearch();
                    this.requestSearchToSelectedData();
                    this.fetchSessions(this.requestSearch);
                }
            },
            '$store.state.appStore.sessionDeleted': {
                deep: true,
                handler: function (newValue) {
                    if (newValue && this.$route.name === "sessionSearchRoute") {
                        this.$store.commit("setSessionDeletedStatus", false);
                        this.fetchSessions(this.requestSearch);
                    }
                }
            },
            '$store.state.appStore.sessionLocked': {
                deep: true,
                handler: function (newValue) {
                    if (newValue && this.$route.name === "sessionSearchRoute") {
                        this.$store.commit("setLockSessionStatus", false);
                        this.fetchSessions(this.requestSearch);
                    }
                }
            },
            '$store.state.appStore.sessionUnlocked': {
                deep: true,
                handler: function (newValue) {
                    if (newValue && this.$route.name === "sessionSearchRoute") {
                        this.$store.commit("setUnlockSessionStatus", false);
                        this.fetchSessions(this.requestSearch);
                    }
                }
            },
            '$store.state.appStore.sessionEdited': {
                deep: true,
                handler: function (newValue) {
                    if (newValue && this.$route.name === "sessionSearchRoute") {
                        this.$store.commit("setEditSessionStatus", false);
                        this.fetchSessions(this.requestSearch);
                    }
                }
            },
            '$store.state.appStore.sessionAdded': {
                deep: true,
                handler: function (newValue) {
                    if (newValue && this.$route.name === "sessionSearchRoute") {
                        this.$store.commit("setAddSessionStatus", false);
                        this.fetchSessions(this.requestSearch);
                    }
                }
            }
        },
        computed: {
            requestQuery() {
                return this.$route.query;
            },

            getCurrentItems() {
                return (this.requestSearch.page - 1) * this.requestSearch.itemsPerPage + this.sessionList.length;
            },
            sessionDeleted() {
                return this.$store.state.userStore.sessionDeleted;
            }
        },
        async mounted() {

            if (this.$store.state.appStore.sessionEdited == true) {
                this.$store.commit("setEditSessionStatus", false);
            }

            if (this.$store.state.appStore.sessionAdded == true) {
                this.$store.commit("setAddSessionStatus", false);
            }

            await this.fetchGroups();
            this.queryParamsToRequestSearch();
            this.objectsToAvailableOptions();
            this.requestSearchToSelectedData();

            this.fetchSessions(this.requestSearch);
        },
        methods: {
            checkSkippedActions(isEnd) {
                var skippedActionId = [15,16,20,30,80,96];
                if (this.bipModule == 0 || isEnd == true) {
                    skippedActionId.push(95);
                }
               
                if (isEnd == true) {
                    skippedActionId.push(99);
                    skippedActionId.push(60);
                    skippedActionId.push(40);
                    skippedActionId.push(0);
                    skippedActionId.push(10);
                    skippedActionId.push(65);
                    skippedActionId.push(64);
                    skippedActionId.push(68);
                    skippedActionId.push(69);
                }
                else {
                    skippedActionId.push(98);
                }

                return skippedActionId;
            },
            async fetchGroups() {
                try {
                    this.isLoading = true;
                    let groups = this.$store.state.appStore.groups;
                    if (groups.length !== 0) {
                        this.allGroups = [...groups];
                        this.allGroups = this.allGroups.filter(item => {
                            return item.can_sessions;
                        })
                        return;
                    }
                    
                    let response = await ContactService.index(false);
                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }
                    this.allGroups = response.data.filter(item => {
                        return item.can_sessions;
                    });

                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading = false;
                }
            },

            async fetchSessions(request) {
                try {
                    this.isLoading = true;
                    this.$store.commit("showLoader");
                    let response = await SessionService.searchSession(request)

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.sessionList = response.data.items;

                    if (this.sessionList == null || this.sessionList == undefined)
                        this.sessionList = [];

                    for (var i = 0; i < this.sessionList.length; i++) {
                        this.sessionList[i].skippedActions = this.checkSkippedActions(this.sessionList[i].is_end);
                    }
                    
                    this.requestSearch.page = parseInt(response.data.paginated.currentPage);
                    this.requestSearch.itemsPerPage = parseInt(response.data.paginated.itemsPerPage);
                    this.totalItems = parseInt(response.data.paginated.totalItems);
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading = false;
                    this.$store.commit("hideLoader");
                }
            },

            changePage(page) {
                this.requestSearch.page = page;
            },
            requestSearchToSelectedData() {
                this.options.groups.selected = this.allGroups
                    .filter(group => { return this.requestSearch.group.includes(group.id) })
                    .map(item => {
                        return {
                            value: item.id,
                            label: item.name,
                        }
                    })
            },

            filterGroupChange() {
                this.requestSearch.page = 1;
                this.requestSearch.group = this.options.groups.selected.map(item => { return item.value });
            },

            filterGroupClear() {
                this.requestSearch.group = []
            },

            changeDateFrom() {
                if (this.requestSearch.dateFrom === '')
                    return;

                this.requestSearch.page = 1;

                let dateFrom = Date.parse(this.requestSearch.dateFrom);
                let dateTo = Date.parse(this.requestSearch.dateTo);

                if (dateFrom > dateTo) {
                    this.requestSearch.dateFrom = this.requestSearch.dateTo;
                    this.$swal(AlertHelper.errorAlert("Data poczatkowa nie może być późniejsza niż data końcowa!"))
                }
            },

            changeDateTo() {
                
                if (this.requestSearch.dateFrom === '')
                    return;

                this.requestSearch.page = 1;

                let dateFrom = Date.parse(this.requestSearch.dateFrom);
                let dateTo = Date.parse(this.requestSearch.dateTo);

                if (dateFrom > dateTo) {
                    
                    this.requestSearch.dateTo = this.requestSearch.dateFrom;
                    this.$swal(AlertHelper.errorAlert("Data końcowa nie może być wcześniejsza niż data początkowa!"))
                }
            },

            objectsToAvailableOptions() {
                this.options.groups.available = this.allGroups
                    .map(item => {
                        return {
                            value: parseInt(item.id),
                            label: item.name,
                        }
                    })
            },

            removeGroupFilter(identifier) {
                this.options.groups.selected = this.options.groups.selected.filter(item => {
                    return item.value !== parseInt(identifier)
                });
                this.filterGroupChange();
            },


            queryParamsToRequestSearch() {
                if (this.isObjectHasProperty(this.requestQuery, "group")) {
                    this.requestSearch.group = this.toArrayInt(this.requestQuery.group);
                }

                if (this.isObjectHasProperty(this.requestQuery, "dateFrom")) {
                    this.requestSearch.dateFrom = this.requestQuery.dateFrom;
                }

                if (this.isObjectHasProperty(this.requestQuery, "dateTo")) {
                    this.requestSearch.dateTo = this.requestQuery.dateTo;
                }

                if (this.isObjectHasProperty(this.requestQuery, "page")) {
                    this.requestSearch.page = this.requestQuery.page;
                }

                if (this.isObjectHasProperty(this.requestQuery, "itemsPerPage")) {
                    this.requestSearch.itemsPerPage = this.requestQuery.itemsPerPage;
                }

                if (this.isObjectHasProperty(this.requestQuery, "_r")) {
                    this.requestSearch.group = [];
                    this.requestSearch.dateFrom = null;
                    this.requestSearch.dateTo = null;
                }
            },
            isObjectHasProperty(object, propertyName) {
                return Object.hasOwn(object, propertyName);
            },
            toArrayInt(obj) {
                if (Array.isArray(obj)) {
                    return obj.map(Number);
                }
                return [parseInt(obj)];
            }
        }
    }
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
    .session-search {
        margin: 49px 48px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
        }

        &__bar {
            width: 100%;
            display: flex;
            align-items: center;
            column-gap: 20px;
            flex-wrap: wrap;
            row-gap: 10px;
            &__item {
                display: flex;
                align-items: start;
                flex-direction: column;
                row-gap: 5px;

                &__label {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 128%;
                    color: #00082c;
                }

                &__input {
                    display: flex;
                    align-items: center;
                }
            }

            &__input {
                height: 48px;
                background: #ffffff;
                border: 1px solid #d1d6df;
                box-sizing: border-box;
                box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
                border-radius: 4px;
                width: 300px;
                box-sizing: border-box;
                padding: 12.5px 16px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-right: 0px;

                &:focus {
                    border: 1px solid #00082c;
                }
            }

            &__input-date {
                width: 150px;
            }

            &__button-search {
                height: 48px;
                background: #ffffff;
                border: 1px solid #d1d6df;
                box-sizing: border-box;
                box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-left: 0px;
                position: relative;
                cursor: pointer;

                &::after {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 32px;
                    background: #e2e5eb;
                    top: 8px;
                    left: 0px;
                }
            }

            &__search-icon {
                padding: 14px;
            }
        }

        &__information {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: start;

            &__current-filters {
                display: flex;
                flex-direction: column;
                column-gap: 10px;
                row-gap: 10px;

                &__label {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 128%;
                    color: #00082c;
                }

                &__items {
                    display: flex;
                    flex-direction: row;
                    column-gap: 10px;
                    row-gap: 10px;
                    flex-wrap: wrap;
                }
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            position: relative;
            width: 100%;

            &__item {
                position: relative;
                width: 100%;
            }

            &__no-items {
                background: #ffffff;
                padding: 20px;
                text-align: center;
                color: #00082c;
                font-weight: 600;
                border: 1px solid #dce4ee;
                box-sizing: border-box;
                box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
                border-radius: 12px;
            }
        }
    }
    div.multiselect-placeholder,
    .multiselect-multiple-label {
        font-size: 14px !important;
    }
</style>