<template>
    <section class="esesja-modal">
        <form class="esesja-modal__wraper" @submit="(e) => e.preventDefault()">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Wyślij Email</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('closeEmail');}">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">

                <UserAccountEmailSettingsInfo/>

                <section>
                    <label class="esesja-modal__wraper__body__label">Kontakty</label>
                    <Multiselect ref="searchUsers"
                                 v-model="value"
                                 mode="tags"
                                 placeholder="Wyszukaj..."
                                 :close-on-select="false"
                                 :searchable="true"
                                 class="multiselect-green"
                                 noResultsText="brak wyników"
                                 noOptionsText="brak opcji"
                                 :groups="true"
                                 :options="availableOptions"
                                 :searchFilter="customSearchFilter"
                                 @select="selectedOptions"/>

                </section>
                <label class="esesja-modal__wraper__body__label">Temat</label>
                <section class="esesja-modal__row">
                    <input class="esesja-modal__wraper__body__input" v-model="subject" placecholder="temat" />
                </section>
                <label class="esesja-modal__wraper__body__label">Treść</label>
                <section class="esesja-modal__row">
                    <textarea class="esesja-modal__wraper__body__wyswig" v-model="email"></textarea>
                </section>
                <section class="esesja-modal__row">
                    <div class="email-errors" v-if="errors.length > 0">
                        <h5 class="esesja-modal__wraper__body__label">
                            Błędy
                        </h5>
                        <div>
                            <ul>
                                <li v-for="(item, index) in errors" :key="index">{{ item }}</li>
                            </ul>
                        </div>
                    </div>

                    <h5 class="esesja-modal__wraper__body__label">
                        <button class="upload-btn" @click="addFile()" style="float:right">+ dodaj załącznik</button>
                        Załączniki
                    </h5>
                    <div v-if="files != null && files.length > 0">
                        <ul class="esesja-modal__wraper__body__labelFile" v-for="(item, index) in files" :key="index">
                            <li>
                                {{item.name}} <v-btn icon @click="deleteFile(item)">
                                    <img src="../../../../../assets/icons/components/bin.svg" />
                                </v-btn>
                            </li>
                        </ul>
                    </div>
                    <div v-else>
                        <ul class="esesja-modal__wraper__body__labelFile">
                            <li>Brak załączników...</li>
                        </ul>
                    </div>
                    <label class="esesja-modal__wraper__body__labelFile">*PDF, DOC, DOCX, JPG, PNG</label>
                </section>

                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="this.$emit('closeEmail')"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="sendMessage()" :disabled="value.length == 0 || email.length == 0">Wyślij</button>
                </section>
            </perfect-scrollbar>
        </form>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script charset="utf-8">
    import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
    import UserAccountEmailSettingsInfo from "../../../../Shared/Users/UserAccountEmailSettingsInfo.vue";
    import Multiselect from '@vueform/multiselect'
    import ContactService from "../../../../../services/contact.service";
    //import AlertHelper from '@/helpers/alerts.helper.js';

    export default {
        name: "SendEmail",
        data() {
            return {
                name: "",
                isLoading: false,
                count: 0,
                email: "",
                value: [...this.selected],
                files: [],
                subject: "",
                errors: []
            };
        },
        props: {
            options: Array,
            selected: Array
        },
        components: {
            LoaderInside,
            Multiselect,
            UserAccountEmailSettingsInfo
        },
        computed: {
            availableOptions() {
                return this.options.map(item => ({
                    id: item.id,
                    label: item.label,
                    options: item.options.map(opt => ({
                        value: opt.value,
                        label: opt.label,
                        groupId: item.id
                    }))
                }));
            }
        },
        methods: {
            customSearchFilter(option, query) {
                let localQuery = query.toUpperCase();
                let localcopy = [...this.availableOptions];
                // check if any groups meets criteria
                let filterGroup = localcopy.filter(item => {
                    let copyGroupLabel = item.label.toUpperCase()
                    return copyGroupLabel.includes(localQuery);
                })

                if (filterGroup.length > 0) {
                    let isOptionIsInGroup = filterGroup.filter(item => item.id === option.groupId);
                    return isOptionIsInGroup.length > 0;
                }
                return option.label.toUpperCase().includes(localQuery);
            },
            selectedOptions() {
                this.$refs.searchUsers.clearSearch();
            },
            async sendMessage() {
                try {
                    this.isLoading = true;
                    let formData = new FormData();
                    for (var i = 0; i < this.files.length; i++) {
                        let file = this.files[i];
                        formData.append('files[' + i + ']', file);
                    }

                    formData.append("msg", this.email);
                    formData.append("subject", this.subject);
                    formData.append("ids", this.value);

                    var result = (await ContactService.sendEmailToUsers(formData));
                    if (result.status == 200) {
                        this.value = [];
                        this.email = "";
                        this.files = [];
                        this.$emit("closeEmail");
                        this.$store.commit('setSendMessageSuccess', true);
                        this.$store.commit('setSelectedPeopleToSendMessage', []);
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally{
                    this.isLoading = false;
                }
            },
            addFile() {
                let input = document.createElement('input');
                input.type = 'file';
                input.accept = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.png,.jpg,.jpeg';
                input.multiple = true;
                input.click();
                input.addEventListener('change', async (event) => {
                    this.errors = [];
                    for (let item of event.target.files) {
                        if (item.size > 25000000) {
                            this.errors.push(`Plik ${item.name} jest większy niż 25MB`);
                            continue;
                        }

                        if (this.checkMimeType(item.type) === false) {
                            this.errors.push(`Plik ${item.name} nie jest obsługiwany. Pliki obsługiwane to: DOC,DOCX,PDF,JPG,JPEG,PNG`);
                            continue;
                        }

                        if(this.files.find(obj => obj.name === item.name)){
                            continue;
                        }
                        this.files.push(item);
                    }

                });
            },
            checkMimeType(type) {
                if (type != "application/pdf" && type != "image/jpeg" && type != "image/jpg" &&
                    type != "image/png" && type != "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                    type != "text/plain" && type != "application/msword") {
                    return false;
                } else {
                    return true;
                }
            },
            deleteFile(item) {
                this.files = this.files.filter(obj => obj.name !== item.name);
            },
        },
        mounted() {
        
        },
    };
</script>
<style lang="scss" scoped>
    @import "@vueform/multiselect/themes/default.css";
    @import "../../../../../assets/scss/esesja-modal.scss";
    @import "../../../../../assets/scss/multiselect-modal.scss";

    .email-errors {
        border: 1px solid red;
        background: rgba(255, 0, 0, 0.3);
        padding: 10px;
        margin-bottom: 10px;
    }

    .multiselect-green {
        --ms-tag-bg: #D1FAE5;
        --ms-tag-color: #059669;
    }

    .upload-btn {
        display: flex;
        align-items: center;
        border: 0px;
        padding: 5px 5px;
        background: rgba(220, 228, 238, 0.56);
        border-radius: 4px;
    }
</style>