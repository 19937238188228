<template>
    <header class="header">
        <section class="header-left">
            <section class="header-left-humburger-menu" @click="toggleMenu()">
                <section class="header-left-humburger-menu__button">
                    <img src="../../../assets/icons/navMenu/humburger-menu.svg" />

                </section>
            </section>
            <SearchEngine />
        </section>
        <HeaderUserInfo />
    </header>
</template>

<script>
    import HeaderUserInfo from "./HeaderUserInfo.vue";
    import SearchEngine from "./SearchEngine.vue";
    export default {
        name: "HeaderComponent",
        components: {
            HeaderUserInfo,
            SearchEngine
        },
        data() {
            return {
            };
        },
        computed: {

            humburgerMenuStatus() {
                return this.$store.state.appStore.humburgerMenuState;
            },

            getLoaderStatus() {
                return this.$store.state.userData.isLoading;
            },
        },

        methods: {
            toggleMenu() {
                if (this.humburgerMenuStatus) {
                    this.$store.commit("closeHumburger");
                }
                else {
                    this.$store.commit("openHumburger");
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/breakpoints.scss";

    .header {
        width: 100%;
        box-sizing: border-box;
        padding: 26px 48px 23px 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        position: sticky;
        top: 0px;
        z-index: 6;

        &::after {
            content: "";
            position: absolute;
            background: #dde0e7;
            width: 100%;
            height: 1px;
            bottom: -1px;
            left: 0px;
        }
    }

    .header-left {
        display: flex;
        align-items: center;
        flex-grow: 1;
        &__welcome-text {
            &__icon {
                margin-right: 1px;
            }

            &__text {
                font-size: 16px;
                line-height: 144%;
                color: #333956;

                &--bold {
                    font-weight: 600;
                }

                &--link {
                    color: #d80418;
                    text-decoration: underline;
                    font-weight: 600;
                }
            }
        }

        @media only screen and (max-width:1850px) {
            width: 100%;
        }
    }
    .header-left-humburger-menu {
        cursor: pointer;
        padding: 10px;
        margin-right: 10px;
        &__button img{
            height: 25px;
        }
    }
</style>
