<template>
    <section class="contacts">
        <section class="contacts__header d-flex justify-content-between align-items-baseline gap-3">
            <h1 class="contacts__title">Kontakty</h1>
            <section class="contact-top__action-buttons">
                <ButtonToolbar>
                    <ButtonGroup>
                        <ButtonBasic color="danger"
                                     v-if="adminMenagePrivilige && adminContactPrivilige"
                                     @click="() => $store.commit('isImportContactModalStatus', true)"
                                     size="lg"
                                     v-tooltip="'Importuj kontakty'">
                            <ButtonIcon icon="users-medical" />
                        </ButtonBasic>
                        <ButtonSeparator />
                        <!--<ButtonBasic color="danger"
                             v-if="adminMenagePrivilige && adminContactPrivilige && adminMainPrivilige"
                             @click="getLogins()"
                             size="lg"
                             v-tooltip="'Pobierz dane logowania nowo dodanych radnych'">
                    <ButtonIcon icon="download" />
                </ButtonBasic>-->
                        <ButtonSeparator />
                        <ButtonBasic color="danger"
                                     v-if="adminMenagePrivilige && adminContactPrivilige"
                                     @click="(e) => handleChangeView(e, 'add-group')"
                                     size="lg"
                                     v-tooltip="'Dodaj grupę'">
                            <ButtonIcon icon="add-group" />
                        </ButtonBasic>
                        <ButtonSeparator />
                        <ButtonBasic color="danger"
                                     v-if="adminMenagePrivilige && adminContactPrivilige"
                                     @click="(e) => handleChangeView(e, 'add-contact')"
                                     size="lg"
                                     v-tooltip="'Dodaj kontakt'">
                            <ButtonIcon icon="add-contact" />
                        </ButtonBasic>
                    </ButtonGroup>
                </ButtonToolbar>
            </section>
        </section>

        <section class="contact-top">
            <section class="contact-top__search">
                <SearchInputTextComponent v-model="searchText" :placeholder="'Wyszukaj kontakt...'" />
            </section>
            <section class="contact-top__search">
                <LicensesInfoComponent />
            </section>
        </section>
        <section class="contacts__list" v-if="groupsSearch.length > 0">
            <ContactList :groups="groupsSearch"
                         :addContactHandler="handleChangeView"
                         :sendSmsHandler="handleSendSms"
                         :sendEmailHandler="handleSendEmail"
                         :searchBar="searchText"
                         @reloadGroups="refreshGroups" />

            <button v-if="adminMenagePrivilige && adminContactPrivilige" v-tooltip="'Dodaj kontakt'"
                    class="contacts__add-user-btn"
                    @click="(e) => handleChangeView(e, 'add-contact')">
                <img src="../../../../assets/icons/components/add-contact.svg"
                     class="contacts__add-user-btn-icon" />
            </button>

            <button v-if="adminMenagePrivilige && adminContactPrivilige && $store.state.contacts.selectedPeopleToSendMessage.length > 0" v-tooltip="'Wyślij e-mail do wybranych'"
                    class="contacts__send-message-btn" style="margin-right: 80px;"
                    @click="() => handleSendEmail( 3, null, null, null)">
                <img src="../../../../assets/icons/components/send-mail-white.svg"
                     class="contacts__send-message-btn-icon" />
            </button>

            <button v-if="adminMenagePrivilige && adminContactPrivilige && $store.state.contacts.selectedPeopleToSendMessage.length > 0" v-tooltip="'Wyślij SMS do wybranych'"
                    class="contacts__send-message-btn" style="margin-right: 160px;"
                    @click="() => handleSendSms( 3, null, null, null)">
                <img src="../../../../assets/icons/components/send-sms-white.svg"
                     class="contacts__send-message-btn-icon" />
            </button>

        </section>
        <section v-else class="contact-list-no-result">Brak grup</section>
        <section v-if="currentView !== 'none'">
            <AddContact :groups="groups"
                        v-if="currentView === 'add-contact'"
                        :handler="handleChangeView"
                        @renewGroups="(groups)=>{ this.groups = groups; this.groupsSearch = this.groups.map(a => { return { ...a } }); this.searchBar = '';}"
                        :storedGroupId="storedGroupId" :storedUserEditData="storedUserEditData" />
               
            <AddGroup v-if="currentView === 'add-group'"
                          :handler="handleChangeView"
                          :storedGroupData="storedGroupData"
                          @setGroups="(groups) => (this.groups = groups)"
                          @reloadGroups="refreshGroups" />
        </section>

        <Transition>
            <SMSModal v-if="showSMSModal" @close="closeSMSModal" :options="smsOptions" :selected="selected" />
        </Transition>

        <Transition>
            <EmailModal v-if="showEmailModal" @closeEmail="closeEmailModal" :options="emailOptions" :selected="selectedEmail" />
        </Transition>

        <Transition>
            <ImportContactModal v-if="isImportContactModal" @refreshGroups="refreshGroups" />
        </Transition>
    </section>
</template>

<script>
    import ContactService from "../../../../services/contact.service";
    import { encryptStorage } from '../../../../services/encrypt.service';
    //import ButtonWithIcon from "../../../BasicControls/ButtonWithIcon.vue";
    import ContactList from "./ContactList/ContactList.vue";
    import AddContact from "./Modals/AddContact/AddContact.vue";
    import AddGroup from "./Modals/AddGroup/AddGroup.vue";
    import AlertHelper from '@/helpers/alerts.helper.js';
    import SearchInputTextComponent from "../../../BasicControls/SearchInputTextComponent.vue";
    import LicensesInfoComponent from "./Modals/Infos/LicensesInfo.vue";
    import SMSModal from "../Home/Modals/SendSMS.vue";
    import EmailModal from "../Home/Modals/SendEmail.vue";
    import ImportContactModal from "./Modals/AddContact/ImportContactModal.vue";

    export default {
        name: "contact",
        data() {
            return {
                searchText: "",
                searchBar: "",
                groups: [],
                groupsSearch: [],
                currentView: "none",
                storedGroupId: null,
                storedUserEditData: {},
                storedGroupData: {},
                adminContactPrivilige: encryptStorage.getItem("priv").komunikator,
                adminMainPrivilige: encryptStorage.getItem("priv").uprawnienia,
                adminMenagePrivilige: encryptStorage.getItem("priv").zarzadzanie_kontaktami,
                selected: [],
                smsOptions: [],
                showSMSModal: false,
                selectedEmail: [],
                emailOptions: [],
                showEmailModal: false,
            };
        },
        computed: {
            searchPeopleId() {
                if (this.$route.query.searchPeopleId === undefined || this.$route.query.searchPeopleId === null) {
                    return -1;
                }
                return this.$route.query.searchPeopleId;
            },

            isAnyModalOpen(){
                return this.showEmailModal
                    || this.showSMSModal
                    || this.currentView !== "none"
                    || this.isImportContactModal
            },

            isImportContactModal() {
                return this.$store.state.appStore.importContactModalStatus;
            }
        },
        watch: {
            searchPeopleId: {
                deep: true,
                handler: function (newValue) {
                    try {
                        if (parseInt(newValue) === -1) {
                            return;
                        }
                        this.prepareEditContact(newValue);
                    }
                    catch (e) {
                        console.warn(e);
                    }
                    
                }
            },
            searchText: {
                handler: function (newValue) {
                    if (newValue == null || newValue.length == 0) {
                        this.groupsSearch = this.groups;
                    } else {
                        var tempGroups = this.groups.map(a => { return { ...a } });
                        this.groupsSearch = tempGroups.map((gr) => {
                            var matched = [];
                            gr.people.filter((person) => {
                                var search = newValue.toLowerCase();
                                var complexText = `${person.first_name} ${person.last_name} ${person.email} ${person.phone_number}`.toLowerCase();
                                
                                if (complexText.includes(search)) {
                                    matched.push(person);
                                }
                                
                            });
                            gr.people = matched;
                            return gr;
                        });
                    }
                }
            },

            isAnyModalOpen: {
                deep: true,
                handler: function (val) {
                    document.querySelector('body').style.overflow = val ? 'hidden' : 'auto';
                }
            },
        },
        methods: {
            prepareEditContact(id) {
                this.$store.state.userStore.isLoading = true;
                ContactService.showUserData(id).then(
                    (response) => {
                        this.storedUserEditData = response.data;
                        this.storedUserEditData.groups =
                            this.storedUserEditData.groups.map(
                                (group) => group.id
                            );
                        this.$store.state.userStore.isLoading = false;
                        this.currentView = 'add-contact';
                    }
                );
            },
            async handleSendSms(type, groupId, personId, peoples) {
                try {
                    this.$store.commit("showLoader");
                    var result = (await ContactService.getUsersToSmsOrEmail(1)).data;
                    if (Object.keys(result).length > 0) {
                        this.selected = [];
                        this.smsOptions = result;
                        if (type == 1) {
                            // single
                            this.selected.push(personId);
                        }
                        else if (type == 2) {
                            // group
                            var group = this.smsOptions.filter(x => x.id == groupId);

                            if (group.length > 0) {

                                let people = group[0].options.map(x => x.value);

                                if (people.length > 0) {
                                    for (var i = 0; i < peoples.length; i++) {
                                        if (people.includes(peoples[i].id)) {
                                            this.selected.push(peoples[i].id);
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            // selected in group
                            var peopleListStore = this.$store.state.contacts.selectedPeopleToSendMessage;

                            if (peopleListStore.length > 0) {
                                for (var j = 0; j < this.smsOptions.length; j++) {
                                    let pl = this.smsOptions[j].options.map(x => x.value);
                                    for (var k = 0; k < peopleListStore.length; k++) {
                                        if (pl.includes(peopleListStore[k])) {
                                            if (!this.selected.includes(peopleListStore[k])) {
                                                this.selected.push(peopleListStore[k]);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        
                        this.showSMSModal = true;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            async handleSendEmail(type, groupId, personId, peoples) {
                try {
                    this.$store.commit("showLoader");
                    var result = (await ContactService.getUsersToSmsOrEmail(2)).data;
                    if (Object.keys(result).length > 0) {
                        this.selectedEmail = [];
                        this.emailOptions = result;

                        if (type == 1) {
                            // single
                            this.selectedEmail.push(personId);
                        }
                        else if (type == 2) {
                            // group
                            var group = this.emailOptions.filter(x => x.id == groupId);

                            if (group.length > 0) {
                                let people = group[0].options.map(x => x.value);

                                if (people.length > 0) {
                                    for (var i = 0; i < peoples.length; i++) {
                                        if (people.includes(peoples[i].id)) {
                                            this.selectedEmail.push(peoples[i].id);
                                        }
                                    }
                                }
                            }

                        }
                        else {
                            // selected in group
                            var peopleListStore = this.$store.state.contacts.selectedPeopleToSendMessage;

                            if (peopleListStore.length > 0) {

                                for (var j = 0; j < this.emailOptions.length; j++) {
                                    let pl = this.emailOptions[j].options.map(x => x.value);
                                    for (var k = 0; k < peopleListStore.length; k++) {
                                        if (pl.includes(peopleListStore[k])) {
                                            if (!this.selectedEmail.includes(peopleListStore[k])) {
                                                this.selectedEmail.push(peopleListStore[k]);
                                            }    
                                        }
                                    }
                                }
                            }
                        }
                        this.showEmailModal = true;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            closeSMSModal() {
                this.showSMSModal = false;
                this.smsOptions = [];
                this.selected = [];
                //this.$store.commit('setSelectedPeopleToSendMessage', []);
            },
            closeEmailModal() {
                this.showEmailModal = false;
                this.selectedEmail = [];
                this.emailOptions = [];
                //this.$store.commit('setSelectedPeopleToSendMessage', []);
            },
            handleSearchSubmit(e) {
                e.preventDefault();
            },
            handleChangeView(e, status, additionalData = { id: null, action: "none" }) {
                if (e !== null) {
                    e.preventDefault();
                }
                if (status === "none") {
                    this.storedGroupId = null;
                    this.storedUserEditData = {};
                    this.currentView = status;
                    this.storedGroupData = {};
                }

                if (additionalData.id !== null && additionalData.action === "add-contact-to-group") {
                    this.storedGroupId = additionalData.id;
                    this.currentView = status;
                }

                if (additionalData.id !== null && additionalData.action === "edit-contact") {
                    this.$store.state.userStore.isLoading = true;
                    ContactService.showUserData(additionalData.id).then(
                        (response) => {
                            this.storedUserEditData = response.data;
                            this.storedUserEditData.groups =
                                this.storedUserEditData.groups.map(
                                    (group) => group.id
                                );
                            this.$store.state.userStore.isLoading = false;
                            this.currentView = status;
                        }
                    );
                }

                if ( additionalData.id !== null && additionalData.action === "edit-group") {
                    this.$store.state.userStore.isLoading = true;
                    ContactService.showGroupData(additionalData.id).then(
                        (response) => {
                            this.storedGroupData = response.data;
                            this.$store.state.userStore.isLoading = false;
                            this.currentView = status;
                        }
                    );
                }

                if (additionalData.id === null) {
                    this.currentView = status;
                }
            },
            //async getLogins() {
            //    try {
            //        this.$store.commit("showLoader");
            //        let response = await ContactService.getUsersLoginDataToWord();
            //        if (response.status !== 200) {
            //            throw await Promise.reject(response)
            //        }

            //        const contentType = response.headers['content-type'];
            //        let a = document.createElement('a');
            //        var blob = new Blob([response.data], { 'type': contentType });
            //        a.href = window.URL.createObjectURL(blob);
            //        var filename = response.headers['content-disposition'].split("filename=")[1];

            //        filename = filename.substr(0, filename.indexOf(';'));
            //        filename = filename.replaceAll('"', '');
            //        a.download = filename
            //        a.click();
            //    }
            //    finally {
            //        this.$store.commit("hideLoader");
            //    }
            //},

            async refreshGroups() {
                try {
                    let response = await ContactService.index(false);
                    
                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.groups = response.data;
                    this.groupsSearch = this.groups.map(a => { return { ...a } });
                    this.$store.commit("setGroups", response.data);
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
        },
        components: {
            //ButtonWithIcon,
            ContactList,
            AddContact,
            AddGroup,
            SearchInputTextComponent,
            LicensesInfoComponent,
            SMSModal,
            EmailModal,
            ImportContactModal
        },
        async mounted() {
            this.$store.commit('setSelectedPeopleToSendMessage', []);
            this.$store.commit('setSendMessageSuccess', false);

            if (parseInt(this.searchPeopleId) !== -1) {
                this.prepareEditContact(this.searchPeopleId);
            }

            if (this.adminContactPrivilige) {
                this.$store.commit("showLoader");
                ContactService.index(false).then((response) => {
                    this.groups = response.data;
                    this.groupsSearch = this.groups.map(a => { return { ...a } });
                    this.$store.commit("setGroups", response.data);
                    this.$store.commit("hideLoader");
                });
            }
            else {
                this.$swal(AlertHelper.errorAlert("Brak dostępu do modułu!"));
                this.$router.push({ name: "home" });
            }
        },
    };
</script>

<style lang="scss" scoped>
    .contacts {
        margin: 49px 48px;

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 20px;
        }

        &__add-user-btn {
            position: fixed;
            bottom: 40px;
            right: 48px;
            width: 56px;
            height: 56px;
            border: 0px;
            background: #d80418;
            box-shadow: 8px 8px 28px rgba(173, 3, 19, 0.16);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.2 ease-in-out;

            &:hover {
                background: #ad0313 !important;
            }
        }

        &__send-message-btn {
            position: fixed;
            bottom: 40px;
            right: 48px;
            width: 56px;
            height: 56px;
            border: 0px;
            background: #d80418;
            box-shadow: 8px 8px 28px rgba(173, 3, 19, 0.16);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.2 ease-in-out;

            &:hover {
                background: #ad0313 !important;
            }
        }

        &__add-user-btn-icon {
            width: 25px;
            height: 24px;
        }

        &__send-message-btn-icon {
            width: 25px;
            height: 24px;
        }
    }

    .contact-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        column-gap: 20px;

        &__input {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            width: 326px;
            box-sizing: border-box;
            padding: 12.5px 16px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;

            &:focus {
                border: 1px solid #00082c;
            }
        }

        &__button-search {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-left: 0px;
            position: relative;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 32px;
                background: #e2e5eb;
                top: 8px;
                left: 0px;
            }
        }

        &__form {
            display: flex;
            align-items: center;
        }

        &__search-icon {
            padding: 14px;
        }

        &__action-buttons {
            display: flex;
        }
    }

    .contact-list-no-result {
        width: 100%;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn-without-text {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        border-radius: 4px;
        padding: 13.5px 32px;
        cursor: pointer;
        height: 46px;
        box-sizing: border-box;
        transition: all 0.2s ease-in-out;
        background-color: #ffffff;
        border: 1px solid #bbc2ce;
        background-color: #d80418;
        color: #ffffff;

        &:hover {
            background-color: #ad0313;
        }

        &__icon {
            width: 25px;
            height: 24px;
        }
    }

</style>
