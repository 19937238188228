<template>
    <section class="user-profile">
        <section class="user-profile__header">
            <h2 class="user-profile__header__title">Profil</h2>
            <section class="user-profile__header__action">
                <button class="user-profile__header__button
                           user-profile__header__button--white"
                        @click="() => {this.$store.commit('showShowUserChangeOwnPassword')}">
                    <img src="@/assets/icons/components/lock.svg" />
                    <span class="
                            user-profile__header__text
                            user-profile__header__text--white
                        ">Zmiana hasła</span>
                </button>
                <button class="user-profile__header__button
                           user-profile__header__button--white"
                        @click="() => {this.$store.commit('showShowUserChangeOwnPinModal')}">
                    <img src="@/assets/icons/components/lock.svg" />
                    <span class="
                            user-profile__header__text
                            user-profile__header__text--white
                        ">Zmiana pinu</span>
                </button>
            </section>
        </section>
        <section class="user-profile__errors">
            <section class="user-profile__errors__item" v-if="isUserMustConfirmEmail">
                <section class="user-profile__errors__item__icon">
                    <img src="@/assets/icons/components/error-alert.svg" />
                </section>
                <section class="user-profile__errors__item__body">
                    <p>Adres e-mail nie został potwierdzony.</p> 
                    <p>Na podany adres e-mail został wysłany link aktywacyjny. Jeśli w ciągu 5 minut wiadomość nie pojawi się w skrzynce odbiorczej <a class="user-profile__errors__item__button user-profile__errors__item__button--small" @click="sendNewEmailConfirmation">kliknij</a> aby wysłać ponownie wiadomość z linkiem potwierdzającym.</p>
                </section>
            </section>
        </section>
        <section class="user-profile__body">
            <Avatar />
            <PersonalInfo />
            <AccountSettings />
            <AccountEmailSettings />
            <LastLogins />
        </section>
    </section>
</template>
<script charset="utf-8">
    import Avatar from "./Parts/Avatar.vue"
    import LastLogins from "./Parts/LastLogins.vue"
    import PersonalInfo from "./Parts/PersonalInfo.vue"
    import AccountSettings from "./Parts/AccountSettings.vue"
    import AccountEmailSettings from "./Parts/AccountEmailSettings.vue"
    import UserService from "../../../../services/user.service"
    export default {
        name: "Profile",
        components: {
            Avatar,
            LastLogins,
            PersonalInfo,
            AccountSettings,
            AccountEmailSettings
        },
        computed: {
            notifications() {
                return this.$store.state.userStore.accountErrors;
            },
            isUserMustConfirmEmail() {
                if (this.notifications === undefined)
                    return false;
                return this.notifications.some(e => e.errorTypeHuman === 'noemailconfirmed');
            }
        },
        data() {
            return {

            }
        },


        methods: {
            async sendNewEmailConfirmation() {
                try {
                    this.$store.commit("showLoader");
                    let response = await UserService.sendEmailActivation();
                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .user-profile {
        margin: 49px 48px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        color: #00082c;

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;

            &__action {
                display: flex;
                flex-direction: row;
                column-gap: 20px;
            }

            &__title {
            }

            &__button {
                padding: 13.5px 33px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #d80418;
                border-radius: 4px;
                border: 0px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background-color: #ad0313;
                }

                &--spacing {
                    margin-right: 12px;
                }

                &--white {
                    border: 1px solid #bbc2ce;
                    background-color: white;


                    &:hover {
                        background-color: #e8ebef;
                    }
                }
            }

            &__text {
                color: white;
                font-weight: 500;
                font-size: 16px;
                line-height: 132%;
                margin-left: 8px;

                &--white {
                    color: #00082c;
                }
            }
        }

        &__errors {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            &__item {
                display: flex;
                flex-direction: row;
                column-gap: 20px;
                padding: 20px;
                border: 1px solid red;
                background: rgba(255,0,0, 0.3);
                align-items: center;

                &__button{
                    text-decoration: none;
                    
                    color: #555555;
                    &:hover{

                        text-decoration: underline;
                        cursor: pointer;
                        color: #303030;
                    }
                }
            }
        }

        &__body {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 20px;
            row-gap: 20px;
        }
    }
</style>