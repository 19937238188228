<template>
    <section id="topbnr" class="d-flex flex-row justify-content-center py-0 banner-section" v-if="isShowBanner">
        <a :href="data.link" target="_blank">
            <img class="banner-img" :src="data.banner">
        </a>
    </section>
</template>
<script charset="utf-8">
    import AdminESesjaService from "../../../services/admin.esesja.service";
    export default {
        name: "AdvertisingBanner",
        data(){
            return {
                isShowBanner: false,
                data: {},
            }
        },
        async mounted(){
            try{
                this.isShowBanner = false;
                const res = await AdminESesjaService.getBanner();
                if(res != null && res.status !== undefined && res.status === 200){
                    this.isShowBanner = true;
                    this.data = res.data;
                }
            }
            catch{
                this.isShowBanner = false;
            }
        },
    }
</script>
<style lang="scss">
    .banner-img {
        max-width: 100%;
    }

    .banner-section {
       background: #fff;
       box-shadow: 0 1px 7px 0 rgba(0,0,0,.6);
    }

</style>