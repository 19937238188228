export const appDataStore = {
    state: () => ({
        sessions: [],
        groups: [],
        isAddMeetingVisible: false,
        addMeetingBasedOnTemplateId: null,
        isShowSessionMetadata: false,
        isShowAddProtocol: false,
        isShowImportAgenda: false,
        importSessionId: null,
        sessionId: null,
        sessionMetadataId: null,
        sessionAddProtocolId: null,
        groupId: null,
        meetingDate: "",
        errorVisible: false,
        selectedButtonActionId: 0,
        noticeVisible: false,
        successVisible: false,
        successCustomMessage: null,
        isConfirmationAlertVisible: false,
        isConfirmationAlertWithDataVisible: false,
        alertData: {
            mainText: '',
            buttons: [
                {
                    id: 0,
                    text: '',
                    color: ''
                }
            ]
        },
        sessionAdded: false,
        isInInteractiveMode: false,
        humburgerMenuState: true,
        isSessionCopyPoint: false,
        sessionCopyPointIds: [],
        templateId: null,
        sessionTemplates: [],
        isAddMeetingTemplateVisible: false,
        //Session Duplicate Region
        isSessionDuplicateVisible: false,
        sessionDuplicateId: null,
        isSessionEditMeetingVisible: false,
        sessionEditMeetingId: null,
        sessionEdited: false,
        sessionDeleted: false,
        sessionLocked: false,
        sessionUnlocked: false,
        agendaForSessionImported: false,
        isShowUserChangeOwnPasswordModal: false,
        isShowUserChangeOwnPinModal: false,
        //Notification Region
        isShowNoEmailConfirmDetails: false,
        isShowEditPersonalData: false,

        anySessionModalOpen: false,

        //AttachmentViewerSection
        attachmentModalOpen: false,

        importContactModalStatus: false,
        // errorConnection
        isInternetConnectionDown: false,
        internetConnectionErrorNotification: null,
        confirmationFunctionHandler: () => { },
        confirmationFunctionHandlerAlertWithData: () => { },
    }),
    mutations: {

        openHumburger(state) {
            state.humburgerMenuState = true;
        },
        closeHumburger(state) {
            state.humburgerMenuState = false;
        },
        setSessions(state, payloads) {
            state.sessions = payloads;
        },
        setSessionId(state, payloads) {
            state.sessionId = payloads;
        },
        setTemplateId(state, template) {
            state.templateId = template;
        },
        setGroups(state, payloads) {
            state.groups = payloads;
        },
        setGroupId(state, payloads) {
            state.groupId = payloads;
        },
        showAddMeeting(state, templateId = null) {
            state.isAddMeetingVisible = true;
            state.addMeetingBasedOnTemplateId = templateId; 
        },
        hideAddMeeting(state) {
            state.isAddMeetingVisible = false;
            state.groupId = null;
            state.sessionId = null;
            state.addMeetingBasedOnTemplateId = null;
            state.meetingDate = "";
        },

        showAddMeetingTemplate(state) {
            state.isAddMeetingTemplateVisible = true;
        },

        hideAddMeetingTempplate(state) {
            state.isAddMeetingTemplateVisible = false;
            state.templateId = null;
        },

        showImportSessionAgenda(state, sessionId) {
            state.isShowImportAgenda = true;
            state.importSessionId = sessionId;
        },

        hideImportSessionAgenda(state) {
            state.isShowImportAgenda = false
            state.importSessionId = null;
        },

        setSessionTemplates(state, data) {
            state.sessionTemplates = data;
        },

        showSessionMetadata(state, sessionId) {
            state.isShowSessionMetadata = true;
            state.sessionMetadataId = sessionId;
        },

        showAddProtocol(state, sessionId) {
            state.isShowAddProtocol = true;
            state.sessionAddProtocolId = sessionId;
        },

        hideSessionMetadata(state) {
            state.isShowSessionMetadata = false;
            state.sessionMetadataId = null;
        },

        hideAddProtocol(state) {
            state.isShowAddProtocol = false;
            state.sessionAddProtocolId = null;
        },

        setMeetingDate(state, payloads) {
            state.meetingDate = payloads;
        },
        showError(state) {
            if(state.errorVisible)
                state.errorVisible = false;
            
            state.errorVisible = true;
        },
        hideError(state) {
            state.errorVisible = false;
        },
        showNotice(state) {
            state.noticeVisible = true;
        },
        hideNotice(state) {
            state.noticeVisible = false;
        },
        showSuccess(state) {
            state.successVisible = true;
        },
        showSuccessCustomMessage(state, message) {
            state.successVisible = true;
            state.successCustomMessage = message;
        },
        hideSuccess(state) {
            state.successVisible = false;
            state.successCustomMessage = null;
        },
        turnOffInteractive(state) {
            state.isInInteractiveMode = false;
        },
        turnOnInteractive(state) {
            state.isInInteractiveMode = true;
        },

        showEditMeeting(state, sessionId) {
            state.isSessionEditMeetingVisible = true;
            state.sessionEditMeetingId = sessionId;
        },

        hideEditMeeting(state) {
            state.isSessionEditMeetingVisible = false;
            state.sessionEditMeetingId = null;
        },

        setEditSessionStatus(state, status) {
            state.sessionEdited = status;
        },

        setAddSessionStatus(state, status) {
            state.sessionAdded = status;
        },

        //Session Duplicate Region

        showDuplicateSession(state, sessionId) {
            state.isSessionDuplicateVisible = true;
            state.sessionDuplicateId = sessionId;
        },

        hideDuplicateSession(state) {
            state.isSessionDuplicateVisible = false;
            state.sessionDuplicateId = null;
        },

        showCopySessionPoint(state, pointIds) {
            state.isSessionCopyPoint = true;
            state.sessionCopyPointIds = pointIds;
        },
        hideCopySessionPoint(state) {
            state.isSessionCopyPoint = false;
            state.sessionCopyPointIds = [];
        },
        showConfirmationAlert(state, payloads) {
            state.isConfirmationAlertVisible = true;
            state.confirmationFunctionHandler = () => {
                payloads();
                state.isConfirmationAlertVisible = false;
                state.confirmationFunctionHandler = () => { };
            };
        },
        hideConfirmationAlert(state) {
            state.isConfirmationAlertVisible = false;
            state.confirmationFunctionHandler = () => { };
        },
        setAlertData(state, data) {
            state.alertData = data;
        },
        showConfirmationAlertWithData(state, payloads) {
            state.isConfirmationAlertWithDataVisible = true;
            state.confirmationFunctionHandlerAlertWithData = (response) => {
                state.selectedButtonActionId = response;
                payloads();
                state.isConfirmationAlertWithDataVisible = false;
                state.alertData = {
                    mainText: '',
                    buttons: [
                        {
                            id: 0,
                            text: '',
                            color: ''
                        }
                    ]
                };
                state.confirmationFunctionHandlerAlertWithData = () => { };
            };
        },
        hideConfirmationAlertWithData(state) {
            state.selectedButtonActionId = 0;
            state.isConfirmationAlertWithDataVisible = false;
            state.alertData = {
                mainText: '',
                    buttons: [
                        {
                            id: 0,
                            text: '',
                            color: ''
                        }
                    ]
            };
            state.confirmationFunctionHandlerAlertWithData = () => { };
        },
        setLockSessionStatus(state, status) {
            state.sessionLocked = status;
        },
        setUnlockSessionStatus(state, status) {
            state.sessionUnlocked = status;
        },
        setSessionDeletedStatus(state, status) {
            state.sessionDeleted = status;
        },
        setagendaForSessionImported(state, status) {
            state.agendaForSessionImported = status;
        },
        showShowUserChangeOwnPassword(state) {
            state.isShowUserChangeOwnPasswordModal = true;
        },
        hideShowUserChangeOwnPassword(state) {
            state.isShowUserChangeOwnPasswordModal = false;
        },
        showShowUserChangeOwnPinModal(state) {
            state.isShowUserChangeOwnPinModal = true;
        },
        hidehowUserChangeOwnPinModal(state) {
            state.isShowUserChangeOwnPinModal = false;
        },

        // notificationRegion
        toggleNoEmailConfirmDetails(state, status) {
            state.isShowNoEmailConfirmDetails = status;
        },
        toggleEditPersonalData(state, status)
        {
            state.isShowEditPersonalData = status
        },

        setAnySessionModalOpenStatus(state, status) {
            state.anySessionModalOpen = status;
        },

        setAttachmentModalOpen(state, status) {
            state.attachmentModalOpen = status;
        },


        isImportContactModalStatus(state, status) {
            state.importContactModalStatus = status;
        },

        setIsInternetConnectionDown(state, status){
            state.isInternetConnectionDown = status;
        }
    },
};
