import ApiService from "./api.service";

class ContactService extends ApiService {
    index(ses) {
        return this.get(`Group/groups/${ses}`);
    }

    show(ses) {
        return this.get(`Group/groups/${ses}`);
    }

    showUserData(id) {
        return this.get(`Group/people/${id}`);
    }

    getUserQrCodeForLogin(id) {
        return this.get(`Group/people/qr-code/${id}`);
    }

    showGroupData(id) {
        return this.get(`Group/groups/${id}`);
    }

    checkContactExist(data) {
        return this.post("Group/people/check-contact", data)
    }

    createContact(data) {
        return this.post("Group/people", data);
    }

    createGroup(data) {
        return this.post("Group/groups", data);
    }

    updateContact(id, data) {
        return this.post(`Group/people/${id}`, data);
    }

    updateGroup(id, data) {
        return this.post(`Group/groups/${id}`, data);
    }

    deleteGroup(id) {
        return this.delete(`Group/groups/${id}`);
    }

    deleteContact(id) {
        return this.delete(`Group/people/${id}`);
    }

    getUsersToSmsOrEmail(type) {
        return this.get(`Message/sms/${type}`);
    }

    sendSMSToUsers(data) {
        return this.post(`Message/sms/send`, data)
    }

    sendEmailToUsers(data) {
        return this.post(`Message/email/send`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }

    getUserDataToEdit(id) {
        return this.get(`Group/getUserDataToEdit/${id}`);
    }

    getPublicUserInfo(id) {
        return this.get(`Group/getPublicUserInfo/${id}`)
    }

    addorupdatePublicUserInfo(id, data) {
        return this.post(`Group/addorupdatePublicUserInfo/${id}`, data)
    }

    addOrEditUserPermissionData(uid, data) {
        return this.post(`Group/addOrEditUserPermissionData/${uid}`, data)
    }

    addOrEditUserGroupsPermissionData(lid, data) {
        return this.post(`Group/addOrEditUserGroupsPermissionData/${lid}`, data)
    }

    addOrEditUserLoginData(lid, data) {
        return this.post(`Group/addOrEditUserLoginData/${lid}`, data)
    }

    prepareImageToSend(data) {
        return this.post(`Group/prepareImageToSend`, data)
    }

    getResolutionBeginByYear() {
        return this.get(`Group/getResolutionBeginByYear`)
    }

    setResolutionBeginByYear(data) {
        return this.post(`Group/setResolutionBeginByYear`, data)
    }

    addMultiplePersons(data) {
        return this.post(`Group/addMultiplePersons`, data)
    }

    getUsersLoginDataToWord() {
        return this.get(`Group/getUsersLoginData/to-word`, { responseType: "arraybuffer" })
    }
    downloadUserCalendar(link){
        return this.get(link, { responseType: "arraybuffer" })
    }
}

export default new ContactService();
