<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title" v-if="isResolution">Edytuj nazwę uchwały</h3>
                <h3 class="esesja-modal__wraper__header__title" v-else>Edytuj nazwę załącznika</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('closeUpdateFileName');}">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <label class="esesja-modal__wraper__body__label" v-if="isResolution">Nazwa uchwały</label>
                <label class="esesja-modal__wraper__body__label" v-else>Nazwa załącznika</label>
                <section class="esesja-modal__row">
                    <textarea
                           maxlength="500"
                           rows="3"
                           placeholder="Wpisz nazwę załącznika..."
                           class="esesja-modal__wraper__body__input"
                           v-model="name">
                    </textarea>
                    <CharactersCounter :text="name" maxLenght=500 />
                </section>

                <section>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="public-attachement" v-model="isPublic" @change="changeVisibleFlags()"  />
                        <label class="form-bootstrap-check-label" for="public-attachement">Publiczny</label>
                        <section name="externalTrigger2"
                                 class="tip-btn">
                            <img src="../../../../../assets/icons/components/tooltip.svg"
                                 class="tooltip" />
                            <Hint title="Publiczny załącznik"
                                  text="Aktywny oznacza, że załącznik jest widoczny we wszystkich miejscach w systemie w których został opublikowanych (np. Portal Mieszkańca)." />
                        </section>
                    </div>
                </section>

                <section>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="visible-for-users" v-model="isVisibleForUsers" @change="changePublicFlags()"  />
                        <label class="form-bootstrap-check-label" for="visible-for-users">Widoczny dla użytkowników</label>
                        <section name="externalTrigger2"
                                 class="tip-btn">
                            <img src="../../../../../assets/icons/components/tooltip.svg"
                                 class="tooltip" />
                            <Hint style="left: calc(100% - 310px)" title="Widoczny dla użytkowników"
                                  text="Aktywny oznacza, że załącznik jest widoczny dla wszystkich zalogowanych użytkowników." />
                        </section>
                    </div>
                </section>

                <section>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="register-attachement" v-model="isInRegister" />
                        <label class="form-bootstrap-check-label" for="register-attachement">Rejestr</label>
                        <section name="externalTrigger2"
                                 class="tip-btn">
                            <img src="../../../../../assets/icons/components/tooltip.svg"
                                 class="tooltip" />
                            <Hint title="Widoczny w rejestrze"
                                  text="Aktywny oznacza, że jest on widoczny w rejestrze uchwał." />
                        </section>
                    </div>
                </section>

                <section v-if="att.dateTime != undefined && att.dateTime != null" class="esesja-modal__row">
                    <p style="padding: 10px;">Czas dodania: {{formatDateToDisplay(att.dateTime)}}</p>
                </section>

                <section class="esesja-modal__wraper__body__bottom" style="margin-top: 20px;">
                    <button class="esesja-modal__wraper__body__bottom-button"
                            @click="this.$emit('closeUpdateFileName')">
                        Anuluj
                    </button>
                    <button class="
                        esesja-modal__wraper__body__bottom-button
                        esesja-modal__wraper__body__bottom-button--red
                    "
                            @click="handleSaveClick" :disabled="name === null || name === undefined || (name.length == 0 && !isResolution) || name.length > 500">
                        Zapisz
                    </button>
                </section>
            </perfect-scrollbar>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import Hint from "../../../../BasicControls/Hint.vue";
import AttachmentService from "../../../../../services/attachments.service";
import CharactersCounter from "../../../../../components/BasicControls/CharactersCounter.vue"

export default {
    name: "UpdateFileNameModal",
    data() {
        return {
            name: this.att.name,
            isLoading: false,
            isPublic: this.att.public == 1 ? true : false,
            isInRegister: this.att.show_in_register == 1 ? true : false,
            isVisibleForUsers: this.att.visible_for_users == 1 ? true : false
        };
    },
    props: {
        pointID: Number,
        att: Object,
        isResolution:{
            type: Boolean,
            default: false
        }
    },
    components: {
        LoaderInside,
        Hint,
        CharactersCounter
    },
    methods: {
        changeVisibleFlags() {
            if (this.isPublic) {
                this.isVisibleForUsers = true;
            }
        },
        changePublicFlags() {
            if (!this.isVisibleForUsers) {
                this.isPublic = false;
            }
        },
        handleSaveClick() {
            this.isLoading = true;
            const data = {
                id: this.att.id,
                name: this.name,
                isPublic: this.isPublic,
                isInRegister: this.isInRegister,
                isVisibleForUsers: this.isVisibleForUsers
            };
            AttachmentService.updateFile(this.att.id, this.pointID, data)
                .then(() => {
                    this.name = "";
                    this.isPublic = false;
                    this.$emit("reload");
                    this.$emit("closeUpdateFileName");
                    this.isLoading = false;
                });
        },
        formatDateToDisplay(value) {
            var date = new Date(value)
            var d = date.toLocaleString('default', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            });

            return d;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '../../../../../assets/scss/esesja-modal.scss';

    .esesja-modal__wraper {
        min-width: 500px;
    }

    .switch-section {
        display: flex;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .tooltip {
        position: relative;
        left: 5px;
        top: -3px;
    }

    .tip-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        background-color: transparent;
        width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;

        &:hover .hint {
            opacity: 1;
            display: block;
        }
    }
</style>
