<template>
    <section class="action-box">
        <section class="action-box__icon-wrapper">
            <img :src="require(`../../../../assets/icons/panel/home/${icon}.svg`)" class="action-box__icon" />
            <img :src="require(`../../../../assets/icons/panel/home/${icon}_hover.svg`)" class="action-box__icon action-box__icon--hover" />
        </section>
        <section class="action-box__bottom" >
            <span class="action-box__title">{{ title }}</span>
            <img src="../../../../assets/icons/panel/home/arrow.svg" class="action-box__arrow" />
        </section>
    </section>
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/breakpoints.scss";

.action-box {
    flex-grow:1;
    background: #ffffff;
    border: 1px solid #dce4ee;
    box-sizing: border-box;
    box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
    border-radius: 12px;
    min-width: 231px;
    height: 90px;
    box-sizing: border-box;
    padding: 20px 10px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    // &::after {
    //     content: "";
    //     width: 54px;
    //     height: 54px;
    //     background-color: gray;
    // }

    @media only screen and (max-width:1850px) {
        margin-right: 0px;
    }

    &:hover {
        border: 1px solid #d80418;
    }

    &:hover .action-box__icon-wrapper {
        background: linear-gradient(135deg, #fbe6e8 14.84%, #fdf3f4 85.16%);
    }

    &:hover .action-box__icon--hover {
        opacity: 1;
    }

    &__bottom {
       // display: inline-block;
        float: left;
        margin-top: 5px;
    }

    &__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 122%;
        color: #00082c;
        padding: 8px;
    }

    &__arrow {
        //margin-left: 5px;
    }

    &__icon {
        width: 54px;
        height: 54px;
        z-index: 0;

        &--hover {
            position: absolute;
            width: 23px;
            height: 23px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            z-index: 4;
            transition: all 0.2s ease-in-out;
        }
    }

    &__icon-wrapper {
        margin-bottom: 20px;
        background: linear-gradient(135deg, #e8ecf2 14.84%, #f4f6fa 85.16%);
        width: 54px;
        height: 54px;
        border-radius: 100%;
        transition: all 0.2s ease-in-out;
        position: relative;
        float: left;
    }
}
</style>

<script>
export default {
    name: "ActionBox",
    props: {
        title: String,
        icon: String,
    },
};
</script>
