<template>
    <section class="component" style="position: relative">
        <section class="component__header">
            <span class="component__header__title">
                Ustawienia interfejsu aplikacji
            </span>
        </section>
        <section class="component__body settings">
            <section class="settings__item">
                <section class="column-item in-row">
                    <section class="esesja-modal__row">
                        <div class="form-bootstrap-check form-bootstrap-switch">
                            <input class="form-bootstrap-check-input" type="checkbox" role="switch"
                                   id="menuInteractiveShowQorum_id"
                                   v-model="$store.state.settings.client.menuInteractiveShowQorum"
                                   @change="updateMenuInteractiveShowQorum()" />
                        </div>
                    </section>
                    <label class="esesja-modal__wraper__body__label" for="menuInteractiveShowQorum_id">Wyświetlaj "Sprawdź kworum" w menu</label>
                </section>
            </section>
            <section class="column-item in-row">
                <section class="esesja-modal__row">
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch"
                               id="interactiveModalVotingShowAvatar_id"
                               v-model="$store.state.settings.client.interactiveModalVotingShowAvatar"
                               @change="updateInteractiveModalVotingShowAvatar()" />
                    </div>
                </section>
                <label class="esesja-modal__wraper__body__label" for="interactiveModalVotingShowAvatar_id">Wyświetlaj avatary użytkowników podczas głosowań</label>
            </section>
            <section class="column-item in-row">
                <section class="esesja-modal__row">
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch"
                               id="interactiveModalQuorumShowAvatar_id"
                               v-model="$store.state.settings.client.interactiveModalQuorumShowAvatar"
                               @change="updateInteractiveModalQuorumShowAvatar()" />
                    </div>
                </section>
                <label class="esesja-modal__wraper__body__label" for="interactiveModalQuorumShowAvatar_id">Wyświetlaj avatary użytkowników podczas kworum</label>
            </section>
            <section class="column-item in-row">
                <section class="esesja-modal__row">
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch"
                               id="interactiveDiscussionShowAvatar_id"
                               v-model="$store.state.settings.client.interactiveDiscussionShowAvatar"
                               @change="updateInteractiveDiscussionShowAvatar()" />
                    </div>
                </section>
                <label class="esesja-modal__wraper__body__label" for="interactiveDiscussionShowAvatar_id">Wyświetlaj avatary użytkowników w dyskusji</label>
            </section>
            <section class="column-item in-row">
                <section class="esesja-modal__row">
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch"
                               id="clientLogoShowOw_id"
                               v-model="$store.state.settings.client.clientLogoShowOw"
                               @change="updateClientLogoShowOw()" />
                    </div>
                </section>
                <label class="esesja-modal__wraper__body__label" for="clientLogoShowOw_id">Wyświetlaj herb/logo w oknie wizualizacji</label>
            </section>
            <section class="column-item in-row">
                <section class="esesja-modal__row">
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch"
                               id="clientLogoShowDocuments_id"
                               v-model="$store.state.settings.client.clientLogoShowDocuments"
                               @change="updateClientLogoShowDocuments()" />
                    </div>
                </section>
                <label class="esesja-modal__wraper__body__label" for="clientLogoShowDocuments_id">Wyświetlaj herb/logo w eksportowanych dokumentach</label>
            </section>
            <section class="column-item in-row">
                <section class="esesja-modal__row">
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch"
                               id="clientFooterShowDocuments_id"
                               v-model="$store.state.settings.client.clientFooterShowDocuments"
                               @change="updateClientFooterShowDocuments()" />
                    </div>
                </section>
                <label class="esesja-modal__wraper__body__label" for="clientFooterShowDocuments_id">Wyświetlaj stopkę w eksportowanych dokumentach</label>
            </section>
            <section class="column-item in-row">
                <section class="esesja-modal__row">
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch"
                               id="showClockInOw_id"
                               v-model="$store.state.settings.client.showClockInOw"
                               @change="updateShowClockInOw()" />
                    </div>
                </section>
                <label class="esesja-modal__wraper__body__label" for="showClockInOw_id">Wyświetlaj zegar w oknie wizualizacji</label>
            </section>
            <section class="column-item in-row">
                <section class="esesja-modal__row">
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch"
                               id="userOnlineBasedOnMicrophones_id"
                               v-model="$store.state.settings.client.userOnlineBasedOnMicrophones"
                               @change="updateUserOnlineBasedOnMicrophones()" />
                    </div>
                </section>
                <label class="esesja-modal__wraper__body__label" for="userOnlineBasedOnMicrophones_id">Użytkownicy online na podstawie zalogowanych mikrofonów</label>
            </section>
            <section class="column-item in-row">
                <section class="esesja-modal__row">
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch"
                               id="supportCalendarIntegration_id"
                               v-model="$store.state.settings.client.supportCalendarIntegration"
                               @change="updateSupportCalendarIntegration()" />
                    </div>
                </section>
                <label class="esesja-modal__wraper__body__label" for="supportCalendarIntegration_id">Wsparcie dla integracji z zewnętrznymi kalendarzami</label>
            </section>
        </section>
        <SmallLoader v-if="isLoading" />
    </section>
</template>
<script charset="utf-8">
import settingsService from "../../../../../services/settings.service";
import SmallLoader from "../../../../BasicControls/SmallLoader.vue";
//import alertsHelper from "../../../../../helpers/alerts.helper";

//import SearchLabelComponent from "../../../../BasicControls/SearchLabelComponent.vue";  
//import ButtonOutline from "../../../../BasicControls/Buttons/ButtonOutline.vue";

export default {
    name: "VotingSettings",
    components: {
        SmallLoader,
        //SearchLabelComponent,
        //ButtonOutline
    },
    computed:{
    },
    data() {
        return {
            isLoading: false,
           
        }
    },
    async mounted() {
        try {
            this.isLoading = true;
        }
        finally {
            this.isLoading = false;
        }
    },
    watch:{
      
    },
    methods: {
        async updateMenuInteractiveShowQorum() {
            try {
                this.isLoading = true;
                let data = { ...this.$store.state.settings.client };
                data.menuInteractiveShowQorum = this.$store.state.settings.client.menuInteractiveShowQorum;

                await this._internalUpdateData(data);
            }
            finally {
                this.isLoading = false;
            }
        },

        async updateInteractiveModalVotingShowAvatar() {
            try {
                this.isLoading = true;
                let data = { ...this.$store.state.settings.client };
                data.interactiveModalVotingShowAvatar = this.$store.state.settings.client.interactiveModalVotingShowAvatar;

                await this._internalUpdateData(data);
            }
            finally {
                this.isLoading = false;
            }
        },

        async updateInteractiveModalQuorumShowAvatar() {
            try {
                this.isLoading = true;
                let data = { ...this.$store.state.settings.client };
                data.interactiveModalQuorumShowAvatar = this.$store.state.settings.client.interactiveModalQuorumShowAvatar;

                await this._internalUpdateData(data);
            }
            finally {
                this.isLoading = false;
            }
        },

        async updateInteractiveDiscussionShowAvatar() {
            try {
                this.isLoading = true;
                let data = { ...this.$store.state.settings.client };
                data.interactiveDiscussionShowAvatar = this.$store.state.settings.client.interactiveDiscussionShowAvatar;

                await this._internalUpdateData(data);
            }
            finally {
                this.isLoading = false;
            }
        },

        async updateClientLogoShowOw() {
            try {
                this.isLoading = true;
                let data = { ...this.$store.state.settings.client };
                data.clientLogoShowOw = this.$store.state.settings.client.clientLogoShowOw;

                await this._internalUpdateData(data);
            }
            finally {
                this.isLoading = false;
            }
        },

        async updateClientLogoShowDocuments() {
            try {
                this.isLoading = true;
                let data = { ...this.$store.state.settings.client };
                data.clientLogoShowDocuments = this.$store.state.settings.client.clientLogoShowDocuments;

                await this._internalUpdateData(data);
            }
            finally {
                this.isLoading = false;
            }
        },

        async updateClientFooterShowDocuments() {
            try {
                this.isLoading = true;
                let data = { ...this.$store.state.settings.client };
                data.clientFooterShowDocuments = this.$store.state.settings.client.clientFooterShowDocuments;

                await this._internalUpdateData(data);
            }
            finally {
                this.isLoading = false;
            }
        },

        async updateShowClockInOw(){
            try {
                this.isLoading = true;
                let data = { ...this.$store.state.settings.client };
                data.showClockInOw = this.$store.state.settings.client.showClockInOw;

                await this._internalUpdateData(data);
            }
            finally {
                this.isLoading = false;
            }
        },

        async updateUserOnlineBasedOnMicrophones(){
            try {
                this.isLoading = true;
                let data = { ...this.$store.state.settings.client };
                data.userOnlineBasedOnMicrophones = this.$store.state.settings.client.userOnlineBasedOnMicrophones;

                await this._internalUpdateData(data);
            }
            finally {
                this.isLoading = false;
            }
        },

        async updateSupportCalendarIntegration(){
            try {
                this.isLoading = true;
                let data = { ...this.$store.state.settings.client };
                data.supportCalendarIntegration = this.$store.state.settings.client.supportCalendarIntegration;

                await this._internalUpdateData(data);
            }
            finally {
                this.isLoading = false;
            }
        },

        async _internalUpdateData(data) {
            return await settingsService.updateClientSettings(data);
        },

    }
}
</script>
<style lang="scss">
@import "@/assets/scss/user-profile-component.scss";

.in-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.in-row .esesja-modal__row {
    margin-bottom: 0px;
    margin-right: 10px;
}

.esesja-modal__wraper__body__label {
    cursor: pointer;
}

.settings {
    padding: 15px 10px;
}
</style>