<template>
    <section class="people-box">
        <section class="people-box-data-wrapper">
            <input type="checkbox" @change="checkedPeople()" v-model="checked" class="checkboxForMessage" v-tooltip="'Oznaczenie do wysłania sms/email'" />
            <section class="people-box__profile-wrapper">
                <img :src="avatarToUpdate"
                     class="people-box-data-wrapper__profile-pic" />
                <section class="people-box__lock-icon" v-if="isLocked">
                    <img src="../../../../../assets/icons/components/lock.svg" />
                </section>
                <section class="people-box__edit-icon" v-if="!isLocked" @click="addFile()" v-tooltip="'Zmień avatar (format: .jpg)'" style="width: 14px;">
                    <img src="../../../../../assets/icons/components/pen.svg" />
                </section>
                <section class="people-box__delete-icon" v-if="!isLocked" @click="deleteAvatar()" v-tooltip="'Usuń avatar'">
                    <img src="../../../../../assets/icons/components/exit-red.svg" style="width: 14px;" />
                </section>
            </section>

            
        <span class="people-box-data-wrapper__text people-box-data-wrapper__text--bold">
            <section class="d-flex flex-row align-items-center gap-2">
                <PulseDot :color="getOnlineColor" v-tooltip="getOnlineStatusTooltip" />
                <section>
                    <p>{{ name }} {{ lastName }}</p> 
                    <span style="font-size: 11px; font-weight: 400;">(ID: {{id}})</span>
                </section>
            </section>

        </span>
            <span class=" people-box-data-wrapper__text people-box-data-wrapper__text--mail">{{ mail }}</span>
            <span class="people-box-data-wrapper__text">{{ number }}</span>
        </section>
        <section class="people-box-action-wrapper">
            <section class="people-box-action-wrapper__on-hover">
                <ButtonOnlyWithIcon class="contact-box-custom-button" v-if="isInUserTable && isSupportCalendarIntegration" icon="download-black" @click="downloadCalendar()" v-tooltip="'Pobierz kalendarz użytkownika'"/>
                <ButtonOnlyWithIcon class="contact-box-custom-button" v-if="isInUserTable && isSupportCalendarIntegration" icon="callendar" @click="copyCalendarLink()" v-tooltip="'Skopiuj link do kalendarza użytkownika'"/>
                <ButtonOnlyWithIcon class="contact-box-custom-button" v-if="isInUserTable && isQrCodeLogin != null && isQrCodeLogin == true" icon="qrcode" @click="() => showQrCode()" v-tooltip="'Kod QR do logowania'"/>
                <ButtonOnlyWithIcon class="contact-box-custom-button" v-if="number.length > 0" icon="send-sms" @click="() => sendSmsHandler( 1, null, this.id, null)" v-tooltip="'Wyślij SMS'"/>
                <ButtonOnlyWithIcon class="contact-box-custom-button" v-if="mail.length > 0" icon="send-mail" @click="() => sendEmailHandler(1, null, this.id, null)" v-tooltip="'Wyślij e-mail'" />
                <ButtonOnlyWithIcon class="contact-box-custom-button" v-if="adminMainPrivilige" icon="lock" @click="() => handlePermissionClick(this.id)" v-tooltip="'Uprawnienia'"/>
                <ButtonOnlyWithIcon class="contact-box-custom-button" v-if="portalMieszkanca == 1" icon="tooltip" @click="() => publicInfoHndler(id, name, lastName)" v-tooltip="'Dane publiczne'" />
            </section>
            <ButtonOnlyWithIcon class="contact-box-custom-button" icon="edit" v-if="adminMenagePrivilige" @click="() => addContactHandler(null, 'add-contact', { id: id, action: 'edit-contact',})" v-tooltip="'Edytuj kontakt'"/>
            <ButtonOnlyWithIcon class="contact-box-custom-button" v-if="!isInUserTable && adminMenagePrivilige" icon="delete" @click="() => handleDeleteClick(this.id)" v-tooltip="'Usuń kontakt'"/>

        </section>
        <PermissionModal v-if="showPermissionModal && adminMainPrivilige" @closePermission="closePermissionModal" :permission="permission" :lid="tempLid" />
        <PublicInfoModal v-if="portalMieszkanca == 1 && showPublicInfoModal" @closePublicInfo="closePublicInfo" :publicInfo="publicInfo" :lid="tempLid" :personName="tempName" />
        <ImageDialog v-if="showImageModal" :imgContent="imgContent" @imguser-update="imageUpdate" @closeImageModal="closeImageModal"></ImageDialog>
        <QrCodeModal v-if="showQRcodeModal" :qrcode="qrcode" :name="name" :lastName="lastName" @closeQrCodeModal="closeQrCodeModal"></QrCodeModal>
    </section>
</template>

<script charset="utf-8">
import ContactService from "../../../../../services/contact.service";
import { encryptStorage } from '../../../../../services/encrypt.service';
import ImageDialog from "../Modals/AddContact/ImageModal.vue";
import QrCodeModal from "../Modals/AddContact/QrCodeModal.vue";
import PermissionModal from "../Modals/AddContact/AddPermission.vue";
import PublicInfoModal from "../Modals/AddContact/PublicInfoModal.vue";
import ButtonOnlyWithIcon from "../../../../BasicControls/ButtonOnlyWithIcon.vue";
//import ButtonWithIconContacts from "../../../../BasicControls/ButtonWithIconContacts.vue";
import CommonHelper from '@/helpers/common.helper.js';
import PulseDot from "../../../../BasicControls/PulseDot.vue";
import { copyToClipboard } from "../../../../../helpers/utils";
import { fileDownloadHelper } from "../../../../../helpers/file.download.helper";

export default {
    name: "People",
    props: {
        name: String,
        lastName: String,
        mail: String,
        number: String,
        id: Number,
        addContactHandler: Function,
        onlineStatus: Number,
        isLocked: Boolean,
        isInUserTable: Boolean,
        avatar: String,
        sendSmsHandler: Function,
        sendEmailHandler: Function,
        isQrCodeLogin: Boolean,
        calendarLink: String
    },
    components: {
        ButtonOnlyWithIcon,
        //ButtonWithIconContacts,
        PermissionModal,
        ImageDialog,
        PublicInfoModal,
        QrCodeModal,
        PulseDot
    },
    computed:{
        isAnyModalOpen(){
                return this.showPermissionModal
                    || this.showPublicInfoModal
                    || this.showImageModal
                    || this.showQRcodeModal
            },
        getOnlineStatusTooltip(){
            switch(this.onlineStatus){
                case 10: return "Użytkownik nieaktywny";
                case 30: return "Użytkownik online";
                default: return "Użytkownik offline";
            }
        },
        getOnlineColor(){
            switch(this.onlineStatus){
                case 10: return "orange";
                case 30: return "green";
                default: return "red";
            }
        },
        isSupportCalendarIntegration(){
            return this.$store.state.settings.client.supportCalendarIntegration;
        }
    },
    data() {
        return {
            showPermissionModal: false,
            showPublicInfoModal: false,
            publicInfo: {},
            permission: {},
            tempName: '',
            adminContactPrivilige: encryptStorage.getItem("priv").komunikator,
            adminMainPrivilige: encryptStorage.getItem("priv").uprawnienia,
            adminMenagePrivilige: encryptStorage.getItem("priv").zarzadzanie_kontaktami,
            portalMieszkanca: encryptStorage.getItem('modules').pm,
            tempLid: 0,
            imgContent: '',
            showImageModal: false,
            showQRcodeModal: false,
            avatarToUpdate: this.avatar,
            checked: false,
            qrcode: '',
            unknownAvatar: require('@/assets/imgs/unknown.png'),
        }
    },
    watch: {
        '$store.state.contacts.sendMessageSuccess': {
            deep: true,
            handler: function () {
                if (this.$route.name === "contact") {
                    this.checked = false;
                    this.$store.commit('setSendMessageSuccess', false);
                }
            }
        },
        '$store.state.contacts.selectedPeopleToSendMessage': {
            deep: true,
            handler: function (nevValue) {
                if (this.$route.name === "contact") {
                    if (nevValue.includes(this.id)) {
                        this.checked = true;
                    }
                    else {
                        this.checked = false;
                    }
                }
            }
        },
        isAnyModalOpen: {
                deep: true,
                handler: function (val) {
                    document.querySelector('body').style.overflow = val ? 'hidden' : 'auto';
                }
            },
    },
    mounted() {
        const storeList = this.$store.state.contacts.selectedPeopleToSendMessage;
        if (storeList.includes(this.id)) {
            this.checked = true;
        }

    },
    methods: {
        checkedPeople() {
            var data = {
                checked: this.checked,
                id: this.id
            };

            this.$emit("checkedPeopleForMessage", data);
        },
        showQrCode() {
            this.$store.commit('showLoader');

            ContactService.getUserQrCodeForLogin(this.id).then((response) => {

                if (response.status == 200) {
                    this.qrcode = response.data;
                    this.showQRcodeModal = true;
                } else {
                    this.qrcode = '';
                    this.showQRcodeModal = false;
                }
                
            }).catch((e) => {
                console.log(e);
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }).finally(() => {
                this.$store.commit('hideLoader');
            });
        },
        closeQrCodeModal() {
            this.qrcode = '';
            this.showQRcodeModal = false;
        },
        async imageUpdate(value) {
            if (value.length <= 0) {
                this.imgContent = '';
            }
            else
            {
                this.imgContent = '';

                try {
                    var t = { image: value, userId: this.id };
                    var res = (await ContactService.prepareImageToSend(t)).data;

                    if (res == true) {
                        this.avatarToUpdate = "https://ssl.esesja.pl/users/" + this.id + ".jpg" + CommonHelper.getRandomVersionImageForUrlRefresh(0, 100);
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }

                this.showImageModal = false;
            }
        },
        closeImageModal() {
            this.showImageModal = false;
        },
        openImageDialog() {
            this.showImageModal = true;
        },
        async deleteAvatar() {
            const actionToFire = async () => {
                await this.imageUpdate(this.unknownAvatar);
            };
            this.$store.commit("showConfirmationAlert", actionToFire);
        },
        addFile() {
            if (this.adminMenagePrivilige && this.adminContactPrivilige) {
                let input = document.createElement('input');
                input.type = 'file';
                input.accept = '.jpg,.jpeg';
                input.multiple = false;
                input.click();
                var thisVue = this;
                input.addEventListener('change', (event) => {
                    if (event.target.files[0].size <= 10000000) {
                        if (this.checkMimeType(event.target.files[0].type)) {
                            var reader = new FileReader();
                            reader.readAsDataURL(event.target.files[0]);
                            reader.onload = function (e) {
                                thisVue.imgContent = e.target.result;
                                thisVue.openImageDialog();
                            }
                        }
                        else {
                            alert("Nieobsługiwany typ pliku. Pliki obsługiwane to: JPG,JPEG")
                        }
                    } else {
                        alert("Zbyt duży rozmiar pliku! Suma załączników nie może przekraczać 10MB");
                    }
                });
            }
        },
        checkMimeType(type) {
            if (type != "image/jpeg" && type != "image/jpg" /*&& type != "image/png"*/) {
                return false;
            } else {
                return true;
            }
        },
        handleDeleteClick(id) {
            const actionToFire = () => {
                this.$store.state.userStore.isLoading = true;
                ContactService.deleteContact(id).then(() => {
                    var d = this.$store.state.appStore.groups;
                    for (var i = 0; i < d.length; i++) {
                        var people = d[i].people;
                        if (people.length > 0) {
                            var index = people.findIndex(x => x.id == id);
                            if (index != -1) {
                                people.splice(index, 1);
                            }
                        }
                    }
                    this.$store.state.userStore.isLoading = false;
                    this.$emit("reloadGroups", d);
                });
            };
            this.$store.commit("showConfirmationAlert", actionToFire);
        },
        async publicInfoHndler(id, name, lastname) {
            try {
                this.$store.commit('showLoader');
                var result = (await ContactService.getPublicUserInfo(id)).data;
                if (Object.keys(result).length > 0) {
                    this.publicInfo = result;
                    this.tempLid = id;
                    this.tempName = name + " " + lastname;
                    this.showPublicInfoModal = true;
                }
            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
            finally{
                this.$store.commit('hideLoader');
            }
        },
        async handlePermissionClick(id) {
            try {
                this.$store.commit('showLoader');
                var result = (await ContactService.getUserDataToEdit(id)).data;
                if (Object.keys(result).length > 0) {
                    this.permission = result;
                    this.tempLid = id;
                    this.showPermissionModal = true;
                }
            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
            finally{
                this.$store.commit('hideLoader');
            }
        },
        closePermissionModal() {
            this.showPermissionModal = false;
            this.tempLid = 0;
            this.permission = {};
        },
        closePublicInfo() {
            this.showPublicInfoModal = false;
            this.tempLid = 0;
            this.publicInfo = {};
            this.tempName = '';
        },
        async copyCalendarLink(){
            try{
                await copyToClipboard(this.calendarLink);
                this.$store.commit("showSuccessCustomMessage", "Skopiowano adres kalendarza do schowka.");
            }
            catch{
                this.$store.commit("showError");
            }
        },
        async downloadCalendar(){
            try{
                this.$store.commit("showLoader");
                let response = await ContactService.downloadUserCalendar(this.calendarLink);
                if(response.status !== 200){
                    throw await Promise.reject(response);
                }

                fileDownloadHelper(response);
                this.$store.commit("showSuccessCustomMessage", "Pomyślnie pobrano kalendarz użytkownika");
            }
            catch{
                this.$store.commit("showError");
            }
            finally{
                this.$store.commit("hideLoader");
            }
        }
    },
};
</script>

<style lang="scss" scoped>

    .checkboxForMessage {
        margin-right: 10px;
        cursor: pointer;
    }

    input[type=checkbox] {
        transform: scale(1.5);
    }

    .people-box-data-wrapper {
        display: flex;
        align-items: center;

        &__text {
            font-weight: 500;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;

            &--bold {
                font-weight: 600;
                width: 248px;
                margin-left: 10px;
                position: relative;

                @media only screen and (max-width: 1600px) {
                    width: 158px;
                }
            }

            &--active {
                &::after {
                    content: "";
                    position: absolute;
                    left: -20px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 8px;
                    height: 8px;
                    background: #60a800;
                    box-shadow: 1px 2px 4px rgba(65, 114, 0, 0.16);
                    border-radius: 100%;
                }
            }

            &--unactive {
                &::after {
                    content: "";
                    position: absolute;
                    left: -20px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 8px;
                    height: 8px;
                    background: #ffb800;
                    box-shadow: 1px 2px 4px rgba(65, 114, 0, 0.16);
                    border-radius: 100%;
                }
            }

            &--mail {
                width: 371px;
                text-overflow: ellipsis;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                padding-right: 10px;

                @media only screen and (max-width: 1600px) {
                    width: 300px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }

        &__profile-pic {
            width: 35px;
            height: 35px;
            vertical-align: middle;
            border-radius: 50%;
        }
    }

    .people-box {
        padding: 14px 16px;
        background: #ffffff;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 12px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover .people-box-action-wrapper__on-hover {
            display: flex;
        }

        &__profile-wrapper {
            position: relative;
        }

        &__edit-icon {
            position: absolute;
            top: 25px;
            left: -8px;
            width: 16px;
            height: 16px;
            background-color: #e8ecf2;
            border: 2px solid white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        &__delete-icon {
            position: absolute;
            top: 25px;
            left: 20px;
            width: 16px;
            height: 16px;
            background-color: #e8ecf2;
            border: 2px solid white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        &__lock-icon {
            position: absolute;
            top: -8px;
            left: -10px;
            width: 22px;
            height: 22px;
            background-color: #e8ecf2;
            border: 2px solid white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

.people-box-action-wrapper {
    display: flex;
    flex-wrap: nowrap;

    &__on-hover {
        display: none;
        transition: all 0.3s ease-in-out;
        .contact-box-custom-button{

            &:first-of-type{
                border-top-left-radius: 4px !important;
                border-bottom-left-radius: 4px !important;
            }

            &:last-of-type{
                border-right: none !important;
                border-top-right-radius: 0px !important;
                border-bottom-right-radius: 0px !important;
            }
        }
    }
    &:hover{
        .contact-box-custom-button{
            &:first-of-type{
                border-top-left-radius: 0px !important;
                border-bottom-left-radius: 0px !important;
            }
        }
    }
    .contact-box-custom-button{
        margin: 0;
        border-left: 1px solid #bbc2ce;
        border-top: 1px solid #bbc2ce;
        border-bottom: 1px solid #bbc2ce;
        border-right: none;
        border-radius: 0px;
        &:first-of-type{
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }
        &:last-of-type{
            border-right: 1px solid #bbc2ce;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}
</style>
