<template>
    <footer>
        <section class="footer-info d-flex flex-row justify-content-center py-2">
            <p>Potrzebujesz pomocy? Skontaktuj się dzwoniąc na infolinię (pn.-pt. 8:00-16:00) <b>61 666 19 77</b> lub wysyłając zgłoszenie na adres <b>pomoc@mwc.pl</b> podając identyfikator <b>{{this.$store.state.userStore.userData.client_id}}</b> </p>
        </section>
    </footer>
</template>

<script>
    export default {
        name: "FooterComponent",
        components: {

        },
        data() {
            return {
            };
        },
        computed: {

        },
        methods: {
            
        }
    };
</script>

<style lang="scss" scoped>
    .footer-info {
        background: white;
        font-size: 12px;
        text-align: center;
    }
</style>
