<template>
    <section class="header-right">
        <section>
            <p class="client-name-big">{{this.$store.state.userStore.userData.client_name}}</p> 
        </section>
        <section class="header-right__icon-box pointer-notyfication header-online-users" @click="toggleUserOnlineMenu">
            <img src="@/assets/icons/panel/header/users.svg" class="header-right__icon" />
            <section class="header-right__notification">
                {{ userOnlineNumber }}
            </section>
        </section>
        <section class="header-right__icon-box pointer-notyfication" @click="toggleNotificationMenu">
            <img src="@/assets/icons/panel/header/notification.svg"
                 class="header-right__icon" />
            <section class="header-right__notification"
                     v-if="notifications_number > 0">
                {{ notifications_number }}
            </section>
        </section>
        <section class="header-right__user" @click="toggleUserDropdown">
            <img :src="loadAvatar"
                 class="header-right__user-pic" />
            <section class="header-right__container">
                <h5 class="header-right__logged-as">Kadencja {{this.$store.state.settings.cadenceYears}}</h5>
                <p class="header-right__user-name">
                    <PulseDot color="green" style="width: 8px; height: 8px; margin: 0;" />
                    {{ userFullName }}
                </p>
                <p class="client-name" >{{this.$store.state.userStore.userData.client_name}}</p>
            </section>
            <img src="@/assets/icons/panel/header/arrow.svg"
                 class="header-right__arrow" :class="{ opened: showUserDropdown }" />
        </section>
        <Transition>
            <UserNotification v-if="showNotificationDropdown" />
        </Transition>
        <Transition>
            <UserOnline v-if="showUsersOnlineDropdown" />
        </Transition>
        <Transition>
            <section class="header-right__user-dropdown" v-if="showUserDropdown">
                <section class="header-right__user-dropdown__menu">
                    <router-link :to="{ name: 'user-profile' }" class="header-right__user-dropdown__menu__item" @click="() =>{this.showUserDropdown = false;}">
                        <img src="@/assets/icons/navMenu/informations.svg" class="header-right__user-dropdown__menu__item__icon" />
                        <span class="header-right__user-dropdown__menu__item__text">Profil</span>
                    </router-link>
                    <a class="header-right__user-dropdown__menu__item" @click="() => {this.$store.commit('showShowUserChangeOwnPassword'); this.showUserDropdown = false;}">
                        <img src="@/assets/icons/components/lock.svg" class="header-right__user-dropdown__menu__item__icon" />
                        <span class="header-right__user-dropdown__menu__item__text">Zmiana hasła</span>
                    </a>
                    <a class="header-right__user-dropdown__menu__item" @click="() => {this.$store.commit('showShowUserChangeOwnPinModal'); this.showUserDropdown = false;}">
                        <img src="@/assets/icons/components/lock.svg" class="header-right__user-dropdown__menu__item__icon" />
                        <span class="header-right__user-dropdown__menu__item__text">Zmiana pinu</span>
                    </a>
                    <router-link :to="{ name: 'versions-history' }" class="header-right__user-dropdown__menu__item" @click="() => { this.showUserDropdown = false;}">
                        <img src="@/assets/icons/components/file-zipper-light.svg" class="header-right__user-dropdown__menu__item__icon" />
                        <span class="header-right__user-dropdown__menu__item__text">Historia aktualizacji</span>
                    </router-link>
                    <router-link :to="{ name: 'client-settings' }" class="header-right__user-dropdown__menu__item" @click="() => { this.showUserDropdown = false;}">
                        <img src="@/assets/icons/components/gear-complex.svg" class="header-right__user-dropdown__menu__item__icon" />
                        <span class="header-right__user-dropdown__menu__item__text">Ustawienia</span>
                    </router-link>
                    <a class="header-right__user-dropdown__menu__item" @click="lockApplication">
                        <img src="@/assets/icons/navMenu/logout.svg" class="header-right__user-dropdown__menu__item__icon" />
                        <span class="header-right__user-dropdown__menu__item__text">Zablokuj</span>
                    </a>
                    <a class="header-right__user-dropdown__menu__item" @click="logoutUser">
                        <img src="@/assets/icons/navMenu/logout.svg" class="header-right__user-dropdown__menu__item__icon" />
                        <span class="header-right__user-dropdown__menu__item__text">Wyloguj</span>
                    </a>
                </section>
            </section>
        </Transition>
    </section>
</template>
<script charset="utf-8">
    import UserService from "../../../services/user.service";
    import UserNotification from "./User/UserNotification.vue";
    import UserOnline from "./User/UserOnline.vue"
    import PulseDot from "../../BasicControls/PulseDot.vue";
    export default {
        name: "HeaderUserInfo",
        components: {
            UserNotification,
            UserOnline,
            PulseDot
        },
        data() {
            return {
                showUserDropdown: false,
                showNotificationDropdown: false,
                showUsersOnlineDropdown: false, 
            };
        },
        computed: {
            loadAvatar() {
                return this.$store.state.userStore.userData.avatar;
            },
            userFullName() {
                if (this.$store.state.userStore.isLogged) {
                    return `${this.$store.state.userStore.userData.first_name} ${this.$store.state.userStore.userData.last_name}`;
                }
                return null;
            },
            getLoaderStatus() {
                return this.$store.state.userData.isLoading;
            },
            notifications_number() {
                if (this.$store.state.userStore === undefined)
                    return 0;

                if (this.$store.state.userStore.accountErrors === undefined)
                    return 0;

                return this.$store.state.userStore.accountErrors.length;
            },
            userOnlineNumber(){
                const peopleOnline = this.$store.state.userStore.online ?? []
                return peopleOnline.length;
            }
        },
        async mounted() {
            document.addEventListener('click', this.close)
            await this.fetchCurrentAccountError();
        },
        beforeDestroy() {
            document.removeEventListener('click', this.close)
        },
        methods: {
            async fetchCurrentAccountError() {
                try {
                    await UserService.getAccountErrors();
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
            },
            toggleUserDropdown() {
                this.showUserDropdown = !this.showUserDropdown;
            },

            toggleNotificationMenu() {
                this.showNotificationDropdown = !this.showNotificationDropdown;
            },

            toggleUserOnlineMenu(){
                this.showUsersOnlineDropdown = !this.showUsersOnlineDropdown;
            },

            logoutUser() {
                UserService.logout();
            },
            close(e) {
                if (!this.$el.contains(e.target)) {
                    this.showUserDropdown = false;
                    this.showNotificationDropdown = false;
                }
            },
            lockApplication() {
                UserService.updateLockState(true);
            },

            getNotifyTitle(item) {
                switch (item.errorTypeHuman) {
                    case "noemailconfirmed": return "Adres e-mail nie został potwierdzony";
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    .client-name {
        display: none;
        font-size: 10px;
        font-style: italic;
    }

    .client-name-big {
        display: block;
        font-size: 14px;
        font-style: italic;
        text-align: center;
        margin-right: 10px;
    }

    @media screen and (max-width: 1800px) {
        .client-name {
            display:block;
        }

        .client-name-big {
            display: none;
        }
    }

    @media screen and (max-width: 1450px) {
        .header-right__container{
            display: none;
        }
        .header-right {
            &__arrow{
                margin-left: 10px !important;
            }
        }
    }

    .pointer-notyfication {
        cursor: pointer;
    }

    .header-right {
        display: flex;
        align-items: center;

        &__client-logo {
            width: 60px;
        }

        &__user {
            padding-left: 17px;
            height: 46px;
            border-left: 1px solid #e8ebef;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        &__icon-box {
            position: relative;
            height: 48px;
            border-left: 1px solid #e8ebef;
            display: flex;
            align-items: center;
            padding: 0px 15px;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #e8ebef;
            }
        }

        &__notification {
            position: absolute;
            top: 5px;
            right: 14px;
            width: 22px;
            height: 16px;
            background: #d80418;
            box-shadow: 3px 4px 8px rgba(173, 3, 19, 0.16);
            border-radius: 100px;
            color: white;
            font-size: 11px;
            display: flex;
            align-items: center;
            justify-content: center;


            &__menu {
                position: absolute;
                background: #fff;
                right: 341px;
                top: 96px;
                border-left: 1px solid #dde0e7;
                z-index: 7;
                border-bottom: 1px solid #dde0e7;
                border-right: 1px solid #dde0e7;
                border-radius: 0 0 20px 20px;
                box-shadow: 0px 10px 9px 0px #b1b1b1;
                display: flex;
                flex-direction: column;
                padding-bottom: 20px;

                &__item {
                    padding: 10px 20px;
                    display: flex;
                    flex-direction: row;
                    column-gap: 10px;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;
                    align-items: center;

                    &__body {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }

        &__user-pic {
            width: 44px;
            height: 44px;
            margin-right: 7px;
            vertical-align: middle;
            border-radius: 50%;
        }

        &__logged-as {
            font-weight: 600;
            font-size: 11px;
            line-height: 120%;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #808396;
            margin: 4px 0px;
            white-space: nowrap;
        }

        &__user-name {
            font-weight: 600;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
            position: relative;
            white-space: nowrap;

            // &::after {
            //     content: "";
            //     position: absolute;
            //     width: 8px;
            //     height: 8px;
            //     background-color: #60a800;
            //     box-shadow: 1px 2px 4px rgba(65, 114, 0, 0.16);
            //     border-radius: 100%;
            //     left: 0px;
            //     top: 50%;
            //     transform: translateY(-50%);
            // }
        }

        &__arrow {
            margin-left: 31px;
        }

        &__arrow.opened {
            transform: rotate(180deg);
        }

        &__user-dropdown {
            position: absolute;
            background: #fff;
            right: 37px;
            top: 98px;
            border-left: 1px solid #dde0e7;
            z-index: 7;
            border-bottom: 1px solid #dde0e7;
            border-right: 1px solid #dde0e7;
            border-radius: 0 0 20px 20px;
            box-shadow: 0px 10px 9px 0px #b1b1b1;

            &__menu {
                display: flex;
                flex-direction: column;
                padding-bottom: 20px;

                &__item {
                    padding: 10px 20px;
                    display: flex;
                    flex-direction: row;
                    column-gap: 10px;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        background: #e8ebef;
                    }

                    &__icon {
                        filter: brightness(0) invert(0);
                        height: 20px;
                    }
                }
            }
        }
    }

    .header-online-users{
        .header-right__notification{
            right: 8px;
        }
    }
</style>