<template>
    <section class="addquest">
        <SearchInputTextComponent ref="requestSearchInput"
                                  v-model="searchValue" 
                                  :placeholder="'Wyszukaj osobę...'"
                                  @blured="blured"
                                  @focused="focused"
                                  :width="'100%'"
                                  :withButton="false"/>
        <div class="addquest-autocomplete-list" v-if="showSuggestList"
             @mouseover="mouserOverSuggestList = true"
             @mouseleave="mouserOverSuggestList = false">
            <LazyList :data="suggestQuestList"
                      :itemsPerRender="7"
                      :defaultLoading=false
                      containerClasses="list"
                      defaultLoadingColor="#222">
                <template v-slot="{item}">
                    <DiscussionQuestItem v-if="item.noRecords === undefined || item.noRecords === null || item.noRecords === false"
                                         :item="item"
                                         @addParticipantToRequestSpeechList="addParticipantToRequestSpeechList"
                                         @removeParticipantFromFavorite="removeParticipantFromFavorite"
                                         @addParticipantToFavorite="addParticipantToFavorite"
                                         @giveParticipantSpeech="giveParticipantSpeech"
                                         @removeParticipant="removeParticipant" />

                    <section class="addquest-autocomplete-list-item d-flex justify-content-center"
                             v-else>

                        <div class="addquest-autocomplete-list-item-user-data" style="width: auto !important">
                            <span>Brak wyników</span>
                        </div>
                    </section>
                </template>
            </LazyList>
            <div class="add-new-guest">
                <AddNewQuestButton @enableAddNewGuestModal="enableAddNewGuestModal" />
            </div>
            <div class="add-new-guest">
                <OpenListInNewWindowButton @openInNewWindow="openInNewWindow" />
            </div>
        </div>
        <Transition>
            <AddNewGuestModal v-if="$store.state.interactiveStore.isShowAddNewGuest" />
        </Transition>
    </section>
</template>
<script charset="utf-8">
    import AddNewGuestModal from "./Modals/AddNewGuestModal.vue"
    import LazyList from 'lazy-load-list/vue'
    import SearchInputTextComponent from "../../BasicControls/SearchInputTextComponent.vue"
    import DiscussionQuestItem from "./Discussion/DiscussionQuestItem.vue"
    import AddNewQuestButton from "./Discussion/AddNewQuestButton.vue"
    import OpenListInNewWindowButton from "./Discussion/OpenListInNewWindowButton.vue"

    export default {
        name: "DiscussionQuestSearcher",
        components: {
            AddNewGuestModal,
            SearchInputTextComponent,
            LazyList,
            DiscussionQuestItem,
            AddNewQuestButton,
            OpenListInNewWindowButton
        },
        props: {
            availableQuestList: {
                type: Array,
                required: true,
            },
            currentRequestList: {
                type: Array,
                required: true,
            },
            currentActiveSpeech:{
                type: Object
            }
        },
        emits: [
            "addParticipantToRequestSpeechList",
            "giveParticipantSpeech",
            "removeParticipant",
            "addParticipantToFavorite",
            "removeParticipantFromFavorite",
        ],
        computed:{
            sessionId() {
                return parseInt(this.$route.params.sessionid);
            }
        },
        watch: {
            searchValue: {
                immediate: true,
                handler(val) {
                    this.suggestQuestList = this.internalAvailableQuestList.map(item => { return { ...item } });
                    this.suggestQuestList = Array.from(this.suggestQuestList.filter(item => {
                        // if (this.currentRequestList.some(e => e.userId === item.uid))
                        //     return false;


                        if(this.currentActiveSpeech && this.currentActiveSpeech.userId === item.uid){
                            return false;
                        }

                        if (item.queryString.indexOf(val.toLowerCase()) !== -1) {
                            if (item.name !== undefined && item.name !== null)
                                item.name = item.name.replace(new RegExp(val, 'gi'), "<span class='highlite'>$&</span>");

                            if (item.surname !== undefined && item.surname !== null)
                                item.surname = item.surname.replace(new RegExp(val, 'gi'), "<span class='highlite'>$&</span>");
                            return true;
                        }

                        return false;
                    }));
                    if (this.suggestQuestList.length === 0) {
                        this.suggestQuestList.push({
                            noRecords: true
                        });
                    }
                },
            },
            currentRequestList: {
                immediate: true,
                deep: true,
                handler() {
                    this.suggestQuestList = [...this.internalAvailableQuestList];

                    this.suggestQuestList = Array.from(this.suggestQuestList.filter(item => {
                        
                        if(this.currentActiveSpeech && this.currentActiveSpeech.userId === item.uid){
                            return false;
                        }
                        
                        // if (val.some(e => parseInt(e.userId) === parseInt(item.uid)))
                        //     return false;

                        return true;
                    }));
                }
            },
            availableQuestList: {
                immediate: true,
                handler(val) {
                    this.internalAvailableQuestList = Array.from(val.map(item => {
                        let newItem = { ...item }
                        newItem.queryString = (`${item.name} ${item.surname}`).toLowerCase()
                        return newItem;
                    }));
                }
            }
        },
        data() {
            return {
                suggestQuestList: [],
                searchValue: "",
                showSuggestList: false,
                mouserOverSuggestList: false,
                internalAvailableQuestList: [],
            }
        },
        methods: {
            getUserAvata(uid) {
                return `https://ssl.esesja.pl/users/${uid}.jpg`;
            },

            focused() {
                this.suggestQuestList = [];

                for (const item of this.internalAvailableQuestList) {
                    // if (this.currentRequestList.some(e => e.userId === item.uid))
                    //     continue;

                    
                    if(this.currentActiveSpeech && this.currentActiveSpeech.userId === item.uid)
                        continue;

                    this.suggestQuestList.push(item);
                }
                this.showSuggestList = true
            },

            blured() {
                if (!this.mouserOverSuggestList) {
                    this.searchValue = "";
                    this.showSuggestList = false
                }
            },

            giveParticipantSpeech(uid) {
                this.$emit('giveParticipantSpeech', this.getOriginalQuestItem(uid));
                this.showSuggestList = false;
                this.mouserOverSuggestList = false;
                this.searchValue = "";
            },

            addParticipantToRequestSpeechList(uid, type, requestType) {
                var data = {
                    participant: this.getOriginalQuestItem(uid),
                    type: type,
                    requestType: requestType
                };
                this.$emit('addParticipantToRequestSpeechList', data)
                this.showSuggestList = false;
                this.mouserOverSuggestList = false;
                this.searchValue = "";
            },

            removeParticipant(uid) {
                this.$emit('removeParticipant', this.getOriginalQuestItem(uid))
                this.showSuggestList = false;
                this.mouserOverSuggestList = false;
                this.searchValue = "";
            },

            removeParticipantFromFavorite(uid) {
                this.$emit('removeParticipantFromFavorite', this.getOriginalQuestItem(uid))
                this.showSuggestList = false;
                this.mouserOverSuggestList = false;
                this.searchValue = "";

                this.focusInput();

                var r = this.suggestQuestList.filter(x => x.uid == uid);
                if (r != null && r.length > 0) {
                    r[0].isFavorite = false;
                }
            },

            addParticipantToFavorite(uid) {
                this.$emit('addParticipantToFavorite', this.getOriginalQuestItem(uid))
                this.showSuggestList = false;
                this.mouserOverSuggestList = false;
                this.searchValue = "";

                this.focusInput();

                var r = this.suggestQuestList.filter(x => x.uid == uid);
                if (r != null && r.length > 0) {
                    r[0].isFavorite = true;
                }
            },

            getOriginalQuestItem(uid) {
                return this.internalAvailableQuestList.filter(item => item.uid == uid)[0]
            },

            enableAddNewGuestModal() {
                this.$store.commit('showAddNewGuestModal');
                this.showSuggestList = false;
                this.mouserOverSuggestList = false;
            },

            focusInput() {
                this.$refs.requestSearchInput.$refs.searchInputRef.focus();
            },

            openInNewWindow() {
                const routeData = this.$router.resolve({ 
                    name: 'DiscussionQuestList', 
                    params: {
                        sessionId: this.sessionId
                    }
                });
                window.open(routeData?.href, 'name', 'width=600,height=400');
                this.showSuggestList = false;
                this.mouserOverSuggestList = false;
            }
        }
    }
</script>
<style lang="scss">

    .hide-quest-list{
        display: none !important;
    }

    .addquest {
        padding: 10px;
        position: relative;


        &__label {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            margin-bottom: 8px;
        }

        &__input-text {
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            width: 100%;
            height: 56px;
            font-size: 16px;
            color: #00082c;
        }

        .addquest-autocomplete-list {
            background: #fff;
            position: absolute;
            z-index: 99;
            bottom: 87%;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            margin: 0px 10px;
            column-gap: 10px;
            border-bottom: 1px solid #dde0e7;
            border-left: 1px solid #dde0e7;
            border-right: 1px solid #dde0e7;
            max-height: 55vh;
            -webkit-box-shadow: 1px -7px 9px 0px rgba(177, 177, 177, 1);
            -moz-box-shadow: 1px -7px 9px 0px rgba(177, 177, 177, 1);
            box-shadow: 1px -7px 9px 0px rgba(177, 177, 177, 1);

            .add-new-guest {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 0px;
                border-bottom: 1px solid #d1d6df;

            }
        }
    }
</style>