<template>
    <section class="esesja-search-engine">
        <SearchInputTextComponent v-model="request.searchText"
                                  :width="'100%'"
                                  :placeholder="'Szukaj posiedzeń, użytkowników, komunikatów...'" @click="{showSearchResults = true;}"/>
        <SearchResults v-if="showSearchResults" :resultsList="results" @closeAllSearchSection="closeAllSearchSection" /> 
    </section>
</template>
<script charset="utf-8">
    import SearchInputTextComponent from "@/components/BasicControls/SearchInputTextComponent.vue";
    import SearchResults from "./SearchResults.vue";
    import SearchEngineService from "../../../services/search.engine.service";
    export default {
        name: "SearchEngine",
        components: {
            SearchInputTextComponent,
            SearchResults
        },
        watch: {
            'request.searchText': {
                deep: true,
                async handler(val) {
                    if (val.length > 0) {
                        try {
                            let response = await SearchEngineService.MainSearch(this.request);
                            if (response.status !== 200) {
                                throw await Promise.reject(response);
                            }
                            this.results = response.data;
                        }
                        catch (e) {
                            console.log(e);
                        }
                    }
                    else {
                        this.results = [];
                    }
                }
            }
        },
        mounted() {
            document.addEventListener('click', this.closeClickOutside)
        },
        beforeDestroy() {
            document.removeEventListener('click', this.closeClickOutside)
        },
        methods: {
            closeAllSearchSection() {
                this.showSearchResults = false;
                this.results = [];
                this.request = {
                    searchText: ""
                };
            },
            closeClickOutside(e) {
                if (!this.$el.contains(e.target)) {
                    this.showSearchResults = false;
                    this.results = [];
                    this.request = {
                        searchText: ""
                    };
                }
            }
        },
        data() {
            return {
                request: {
                    searchText: "",
                },
                results: [],
                showSearchResults: false
            }
        }
    }
</script>
<style lang="scss">
    .esesja-search-engine{
        margin:0 50px;
        flex-grow: 1;
        max-width: 500px;
    }
</style>