<template>
    <section :class="statisticsBoxStyle">
        <section class="statistics-box__main">
            <section class="statistics-box__main__icon-wrapper">
                <img :src="require(`@/assets/icons/panel/home/${icon}.svg`)" class="statistics-box__icon" />
            </section>
            <section class="statistics-box__main__count">
                <span>{{ value }}</span>
            </section>
        </section>
        <section class="statistics-box__bottom">
            <span class="statistics-box__label">{{ label }}</span>
        </section>
    </section>
</template>
<script charset="utf-8">
export default {
    name: "GenericStatisticsBox",
    props: {
        icon: {
            type: String,
            required: true,
            default() {
                return "add_meeting"
            }
        },
        label: {
            type: String,
            required: true,
            default() {
                return "Ustaw label :label boxa"
            }
        },
        value: {
            type: Number,
            required: true,
            default() {
                return -1
            }
        },
        variant: {
            type: String,
            default(){
                return "default"
            }
        }
    },
    computed: {
        statisticsBoxStyle(){
            let style = "statistics-box ";

            switch(this.variant){
                case "blue": style += `statistics-box--blue `; break;
                case "red": style += `statistics-box--red`; break;
            }

            return style;
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.statistics-box {
    flex-grow: 1;
    background: #ffffff;
    border: 1px solid #dce4ee;
    box-sizing: border-box;
    box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
    border-radius: 12px;
    min-width: 231px;
    height: 165px;
    box-sizing: border-box;
    padding: 27px;
    transition: all 0.1s ease-in-out;

    &::after {
        content: "";
        width: 64px;
        height: 64px;
        background-color: gray;
    }

    &__main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__icon-wrapper {
            margin-bottom: 20px;
            background: linear-gradient(135deg, #e8ecf2 14.84%, #f4f6fa 85.16%);
            width: 64px;
            height: 64px;
            border-radius: 100%;
            transition: all 0.2s ease-in-out;
            position: relative;
        }

        &__count {
            font-size: 36px;
            font-weight: bold;
        }
    }

    &__bottom {
        display: flex;
    }

    &__label {
        font-weight: 600;
        font-size: 16px;
        line-height: 132%;
        color: #00082c;
    }

    &__icon {
        width: 64px;
        height: 64px;
        z-index: 0;
    }

    &--blue {
        background: $eSesjaBlueGradientBackground;

        .statistics-box__label,
        .statistics-box__main__count{
            color: #fff;
        }
    }

    &--red {
        background: $eSesjaRedGradientBackground;
        .statistics-box__label,
        .statistics-box__main__count{
            color: #fff;
        }
    }
}

@media only screen and (max-width:1450px) {
    .statistics-box {
        min-width: 180px;
        flex: 1 auto;
        height: 120px;
        padding: 17px;

        &__main {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &__icon-wrapper {
                margin-bottom: 10px;
                width: 48px;
                height: 48px;
            }

            &__count {
                font-size: 24px;
                font-weight: bold;
            }
        }

        &__label {
            font-weight: 600;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
        }

        &__icon {
            width: 48px;
            height: 48px;
            z-index: 0;
        }

    }
}
</style>