<template>
    <section class="login">
        <section></section>
        <section class="login__form-wrapper">
            <eSesjaLogo class="login__logo" />
            <form class="login-form" @submit="handleLoginClick">
                <h1 class="login-form__header">Zaloguj się</h1>
                <p class="login-form__text">
                    Aby zalogować się w systemie, podaj login<br />
                    i hasło nadane podczas rejestracji. <br />
                    <br />
                    <strong>Logowanie możliwe tylko dla administratora systemu.</strong>
                </p>

                <WarningBox v-if="loginFail">
                    Podany login bądź hasło nie są poprawne. <br />
                    Spróbuj ponownie.
                </WarningBox>

                <label for="login" class="login-form__label">Login</label>
                <input type="text" name="login" id="login" class="login-form__input-text" v-model="login"
                    placeholder="Wpisz login" autocomplete="off" />

                <label for="password" class="login-form__label">Hasło</label>
                <div class="login-form__password-container">
                    <input type="password" name="password" id="password"
                        class="login-form__input-text login-form__input-text--password" placeholder="Wpisz hasło"
                        v-model="password" autocomplete="off" />
                    <section class="login-form__button-show" @click="handleClickShow">
                        <img src="../assets/icons/eye.svg" />
                    </section>
                </div>
                <div class="login-form__checkbox-wrapper">
                    <section class="checkobx-wrapper">
                        <input type="checkbox" class="checkobx-btn" id="remember_me" v-model="rememberMe" />
                        <section class="checkbox-mark">
                            <img src="../assets/icons/components/accept-mark.svg" />
                        </section>
                        <label for="remember_me" class="login-form__label login-form__label--checkbox">Zapamiętaj moje
                            dane</label>
                    </section>
                </div>
                <section style="padding: 10px 0px;">
                    <router-link :to="{ name: 'UserForgotPassword' }" class="forot-password-link">Zapomniałem
                        hasła</router-link>
                </section>

                <button class="login-form__login-btn" @click="handleLoginClick" type="submit">
                    Zaloguj się
                </button>

                <!--<div style="margin-top:10px; text-align: center">
                    <router-link :to="{name: 'ADFSLogin'}" class="forot-password-link">Logowanie ADFS</router-link>
                </div>-->
            </form>

        </section>
        <eSesjaFooter />
    </section>
</template>

<script charset="utf-8">
//import ErrorMessage from "../components/BasicControls/ErrorMessage.vue";
import eSesjaFooter from "../components/Shared/eSesjaFooter.vue";
import eSesjaLogo from "../components/Shared/eSesjaLogo.vue";
import WarningBox from "../components/BasicControls/WarningBox.vue";
import UserService from "../services/user.service";
import { setErrors } from "../formValidation/main";



export default {
    name: "Login",
    data() {
        return {
            login: "",
            password: "",
            rememberMe: false,
            errors: [],
            loginFail: false,
        };
    },
    components: {
        eSesjaFooter,
        eSesjaLogo,
        WarningBox
        //ErrorMessage,
    },
    methods: {
        async handleLoginClick(e) {
            try{
                e.preventDefault();
                this.loginFail = false;
                await UserService.login(this.login, this.password, this.rememberMe);
            }
            catch(err){
                if (err.errors !== undefined) {
                    this.errors = setErrors(err);
                }
                this.loginFail = true;
                UserService.destroyer();
            }
        },
        handleClickShow(){
            try{
                const passwordElement = document.getElementById("password");
                passwordElement.type = passwordElement.type === "password" ? "text" : "password";
            }
            catch(e){
                console.log(e);
            }
        },
        //passErrorMsg,
        //isError,
        setErrors,
    },
};
</script>

<style lang="scss" scoped>
.forot-password-link {
    margin-top: -20px;
    margin-bottom: 10px;
    color: #333956;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: #6c76a7;
        text-decoration: underline;
    }
}

.login {
    width: 100vw;
    height: 100vh;
    background: radial-gradient(235.38% 418.45% at 0% 0%, #00082c 0%, #004dcc 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-family: Inter;
    position: relative;

    &__logo {
        margin-bottom: 26px;
    }

}

.login-form {
    padding: 24px 40px 28px 40px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(146.23deg, #d5e0f5 0%, #ffffff 100%);
    border: 2px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
    border-radius: 12px;

    &__header {
        text-align: center;
        color: #00082c;
        font-size: 28px;
        font-weight: 600;
    }

    &__text {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        margin-top: 12px;
        margin-bottom: 0px;
        color: #333956;
    }

    &__label {
        margin-bottom: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 145%;
        color: #00082c;
        margin-top: 18px;

        &--checkbox {
            font-size: 14px;
            color: #333956;
            margin-bottom: 0px;
            font-weight: 400;
            z-index: 10;
            margin-top: 0px;
            cursor: pointer;
        }
    }

    &__input-text {
        padding: 12.5px 20px 12.5px 20px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);

        &:focus {
            border: 1px solid #00082c;
        }

        &--password {
            width: 85%;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;
        }

        border-radius: 4px;

        &:focus {
            outline: none;
        }

        &::placeholder {
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            color: #808396;
        }
    }

    &__password-container {
        display: flex;
    }

    &__button-show {
        width: 15%;
        height: 43px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        border-left: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            content: "";
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            height: 40px;
            width: 1px;
            background: #e2e5eb;
        }
    }

    &__login-btn {
        background-color: #d80418;
        padding: 14px 0px;
        color: white;
        border: 0px;
        font-weight: 500;
        font-size: 16px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #ad0313;
        }
    }

    &__err-msg {
        color: #d80418;
        text-align: center;
        margin-bottom: 24px;
    }
}

.checkobx-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 10px;
    margin-top: 16px;
}

.checkobx-btn {
    width: 24px;
    height: 24px;
    position: relative;
    opacity: 1;

    &:checked~.checkbox-mark {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:checked~.add-contact-form__label--checkbox {
        padding-left: 0px;
    }

    &:before {
        content: "";
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
        /*border-radius: 4px;*/
        width: 24px;
        height: 24px;
        position: relative;
        display: block;
        top: 0px;
        left: 0px;
        opacity: 1;
    }
}

.checkbox-mark {
    background: #00082c;
    border: 1px solid #00082c;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
    border-radius: 4px;
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 5;
    display: none;
    top: 0px;
    left: 0px;
    opacity: 1;
}
</style>
