<template>
    <section v-if="routerName !== undefined"
                class="link-button"
                ref="mainContainer"
                @mouseover="
                ()=>
        setMenuElementPosition(
        this.$refs.mainContainer,
        this.$refs.subMenu
        )
        ">
        <router-link :to="{ name: routerName }" class="link-button__wrapper">
            <img :src="require(`@/assets/icons/navMenu/${icon}`)"
                    class="link-button__icon" />
            <span class="link-button__link">{{ name }}</span>
            <span class="link-button__link_text" v-if="this.$store.state.interpellationsStore.isInterpellationAccess == true && this.$store.state.interpellationsStore.interpellatinMenuElementId == linkid && this.$store.state.interpellationsStore.newInterpellationsCount > 0">({{this.$store.state.interpellationsStore.newInterpellationsCount}})</span>
        </router-link>
        <img class="link-button__arrow"
                src="@/assets/icons/navMenu/arrow.svg"
                v-if="additionalMenu !== undefined" />
        <div class="link-button__submenu-wrapper" ref="subMenu">
            <AdditionalMenuMeetings v-if="additionalMenu !== undefined" />
        </div>
    </section>
    <section class="link-button"
             ref="mainContainer2"
             @click="navMenuAction"
             @mouseover="
             ()=>
        setMenuElementPosition(
        this.$refs.mainContainer2,
        this.$refs.subMenu2
        )
        "
        v-else
        >
        <section class="link-button__wrapper">
            <img :src="require(`@/assets/icons/navMenu/${icon}`)"
                 class="link-button__icon" />
            <span class="link-button__link">{{ name }}</span>
        </section>
        <img class="link-button__arrow"
             src="@/assets/icons/navMenu/arrow.svg"
             v-if="additionalMenu !== undefined" />
        <div class="link-button__submenu-wrapper" ref="subMenu2">
            <AdditionalMenuMeetings v-if="additionalMenu !== undefined && name === 'Posiedzenia'" />
            <AdditionalMenuRegister v-if="additionalMenu !== undefined && name === 'Rejestr'" />
            <AdditionalMenuMeetingTheme v-if="additionalMenu !== undefined && abbr === 'szablony-posiedzen'" />
        </div>
    </section>
</template>

<script>
    import AdditionalMenuMeetings from "./AdditionalMenuMeetings.vue";
    import AdditionalMenuRegister from "./AdditionalMenuRegister.vue";
    import AdditionalMenuMeetingTheme from "./AdditionalMenuMeetingTheme.vue";
    import setMenuElementPosition from "@/helpers/setMenuElementPosition";
    import transmissionService from "../../../services/transmission.service";

    export default {
        name: "NavLink",
        props: {
            name: String,
            abbr: String,
            link: String,
            icon: String,
            routerName: String,
            additionalMenu: Object,
            linkid: Number,
        },
        components: {
            AdditionalMenuMeetings,
            AdditionalMenuRegister,
            AdditionalMenuMeetingTheme
        },
        methods: {
            setMenuElementPosition,
            async navMenuAction(){
                switch(this.abbr){
                    case "transmission": await this.authTransmissionUser(); break;
                }
            },
            async authTransmissionUser(){
                try{
                    this.$store.commit("showLoader");
                    await transmissionService.auth();
                }
                finally{
                    this.$store.commit("hideLoader");
                }
            }
        },
    };
</script>

<style lang="scss">
    @import "@/assets/scss/variables.scss";

    counter {
        background: red;
        color: #fff;
        margin-left: 10px;
        padding: 1px 7px;
        border-radius: 40px;
    }

    .router-link-active {
        .link-button {
            background: rgba(255, 255, 255, 0.2);
            border-radius: 0px 4px 4px 0px;

            &__link {
                color: white;
            }

            &__icon {
                filter: brightness(0) invert(1);
            }
        }
    }

    .link-button {
        align-items: center;
        padding: 12px 0px 11px 0px;
        margin-right: 16px;
        padding-left: 32px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        column-gap: 10px;
        justify-content: space-between;
        transition: all 0.3s ease-in-out;

        @media only screen and (max-width:1600px) {
            padding: 10px 0px;
            padding-left: 19px;
            margin-right: 12px;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.2);
            border-radius: 0px 4px 4px 0px;
        }

        &:hover .link-button__link {
            color: white;
        }

        &:hover .link-button__icon {
            filter: brightness(0) invert(1);
        }

        &:hover .additional-menu {
            display: block;
        }

        &--left {
            justify-content: flex-start;

            &--red {
                border-radius: 0px 4px 4px 0px;
                background: rgba(216, 4, 24, 0.9);
                box-shadow: 3px 4px 8px rgba(173, 3, 19, 0.16);

                &:hover {
                    background: rgba(216, 4, 24, 1.0);
                }
            }
        }

        &__link {
            color: $navLink;
            font-weight: 600;
            font-size: 16px;
            line-height: 132%;
            margin-left: 12px;

            @media only screen and (max-width:1600px) {
                font-size: 14px;
            }
        }

        &__link_text {
            color: $navLink;
            font-weight: 600;
            font-size: 16px;
            line-height: 132%;
            margin-left: 5px;

            @media only screen and (max-width:1600px) {
                font-size: 14px;
            }
        }

        &__icon {
            width: 20px;
            height: 20px;
        }

        &__arrow {
            margin-right: 16px;
        }

        &__wrapper {
            display: flex;
            align-items: center;
            flex-grow: 1;
        }

        &__submenu-wrapper {
            position: fixed;
            top: 0px;
            left: 0px;
            z-index: 2;
        }
    }

    @media (max-width: 992px) {

    }

    .collapsed-menu .link-button {
        &__link {
            display: none;
        }
    }

/*   to niech sobie zostanie !!!    
    .expand-menu .link-button .link-button__link {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: initial;
    }*/
</style>
